import React from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../lib/config";
const ProductCard = (props) => {
  const { data } = props;

  return (
    <>
      <div className="product_wrappers_one tw-flex tw-border tw-px-2 tw-py-4 tw-shadow-lg tw-bg-white">
        <div className="thumb tw-w-1/2 tw-flex tw-items-center">
          <Link to={`/product/${data?.slug}`} className="image">
            <img
              src={API_URL + data?.resim?.url1}
              className="tw-w-full tw-object-contain"
              alt="Product"
            />
          </Link>
        </div>
        <div className="content tw-w-1/2 tw-gap-3">
          <h2 className="title tw-font-bold !tw-text-lg tw-text-primary">
            <Link to={`/product/${data?.slug}`}>{data?.name}</Link>
          </h2>
          <h3 className="tw-text-xs">{data?.yerler}</h3>
          <Link to={`/product/${data?.slug}`}>
            <button
              type="button"
              className="tw-text-sm tw-border-2 tw-border-primary tw-rounded-full tw-px-4 tw-py-2 hover:tw-bg-hover"
            >
              Detaylar
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
