import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "font-awesome/css/font-awesome.min.css";

// import Custom Css
import "./assets/css/style.css";
import "./assets/css/color.css";
import "./assets/css/responsive.css";
import "./assets/css/animate.min.css";
import "./index.css";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
