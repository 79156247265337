import React from "react";
import { Link } from "react-router-dom";
import imghac from "../../../assets/img/common/hac.webp";
import imgumre from "../../../assets/img/common/medine.jpg";

const ProductUpCard = (props) => {
  const { data } = props;
  return (
    <>
      <div className="product_wrappers_one tw-border tw-px-2 tw-py-4 tw-shadow-lg">
        <div className="thumb tw-flex tw-items-center tw-justify-center tw-mb-2">
          <Link to={`/hizmetler/${data?.url}`} className="image">
            <img
              src={data?.url === "hac" ? imghac : imgumre}
              className="tw-w-60 tw-h-48 tw-object-contain"
              alt="Product"
            />
          </Link>
        </div>
        <div className="content tw-gap-3">
          <h5 className="title tw-font-bold tw-text-xl ">
            <Link to={`/hizmetler/${data?.url}`}>{data?.name}</Link>
          </h5>
          <p className="tw-text-sm">
            Şirketimiz hac ve umre alanında hizmetlerimiz devam etmektedir.
          </p>
          <div>
            <Link to={`/hizmetler/${data?.url}`}>
              <button
                type="button"
                className="tw-border-2 tw-border-primary tw-rounded-full tw-px-4 tw-py-2 hover:tw-bg-hover"
              >
                Detaylar
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductUpCard;
