import React, { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import Buttonlar from "./Buttonlar";
import axios from "axios";
import { API_URL } from "../../lib/config";
import { FaPlaneDeparture, FaPlaneArrival } from "react-icons/fa";

const UmreTurlari = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/umre");
        setData(response.data?.value);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, []);

  const days = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className="container">
      <div className="row  tw-my-12">
        <div className="col-12 d-flex justify-content-center">
          <Link
            to={"/"}
            className="tw-px-4 tw-py-2 tw-text-red-600 tw-flex tw-items-center tw-gap-2 tw-text-2xl hover:tw-text-red-600"
          >
            <FaHome size={30} color="red" /> Anasayfa
          </Link>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <img
            src={logo}
            className="tw-w-[220px] tw-h-[100px] tw-object-contain"
            alt="logo"
          />
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div className="tw-w-full tw-bg-gradient-to-r tw-from-red-700 tw-to-white tw-text-white tw-px-4 tw-py-3 tw-text-xl tw-font-bold">
            Tur Liste
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <Buttonlar />
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div className="tw-overflow-x-scroll tw-mt-8">
            {data?.map((item) => {
              const startdate = new Date(item?.startDate);
              const finishdate = new Date(item?.finishDate);
              const fsDate = startdate.toLocaleDateString("tr-TR", options);
              const ffDate = finishdate.toLocaleDateString("tr-TR", options);

              const sday = days[startdate.getDay()];
              const fday = days[finishdate.getDay()];
              const mystartdate = `${fsDate}, ${sday}`;
              const myfinishdate = `${ffDate}, ${fday}`;

              return (
                <div className="tw-border-2 tw-border-black tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-4 tw-shadow-lg tw-w-fit">
                  <div className="tw-flex tw-justify-start tw-items-center tw-w-full tw-h-12 tw-text-white">
                    <div className="tw-bg-red-600 tw-rounded-tl-lg  tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-3 tw-h-full">
                      <span className="tw-w-full tw-text-center tw-border-b">
                        Tur Kodu
                      </span>
                      <span>{item?.tourCode}</span>
                    </div>
                    <div className="tw-bg-sky-700 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-3 tw-h-full ">
                      <span className="tw-w-full tw-text-center tw-border-b">
                        {item?.dayCount} Gün
                      </span>
                      <span>{item?.night} Gece</span>
                    </div>
                    <div className="tw-bg-emerald-700 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-3 tw-h-full">
                      <span className="tw-w-full tw-text-center tw-border-b">
                        Kalan Yer
                      </span>
                      <span>{item?.capacity - item?.customerCount}</span>
                    </div>
                    <div className="tw-bg-violet-800 tw-rounded-tr-lg tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-3 tw-h-full">
                      <span className="tw-w-full tw-text-center tw-border-b">
                        Konaklama
                      </span>
                      <span>
                        {item?.hasAccommodation === true ? "Var" : "Yok"}
                      </span>
                    </div>
                  </div>
                  <div className="tw-flex tw-py-2 tw-bg-slate-200 tw-w-full tw-items-center tw-justify-center">
                    <div className="tw-flex tw-items-center tw-justify-start tw-pl-2 tw-gap-3 tw-w-3/12">
                      <FaPlaneDeparture size={32} />
                      <span>Gidiş</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-end tw-pr-2 tw-w-9/12">
                      <span>{mystartdate}</span>
                    </div>
                  </div>
                  <div className="tw-flex tw-py-2 tw-bg-slate-200 tw-w-full tw-items-center tw-justify-center tw-rounded-b-lg">
                    <div className="tw-flex tw-items-center tw-justify-start tw-pl-2 tw-gap-3 tw-w-3/12">
                      <FaPlaneArrival size={32} />
                      <span>Dönüş</span>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-end tw-pr-2 tw-w-9/12">
                      <span>{myfinishdate}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UmreTurlari;
