import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./page/Home";

import Iptal from "./page/KVK/Iptal";
import Gizlilik from "./page/KVK/Gizlilik";
import Hakkımızda from "./page/Hakkimizda/Hakkımızda";
import Iletisim from "./page/Hakkimizda/Iletisim";
import ProductDetail from "./page/ProductDetail";
import Hac from "./page/Hizmetler/Hac";
import UmreTurlari from "./page/UmreTurlari/UmreTurlari";
import Login from "./admin/page/Login";
import Anasayfa from "./admin/page/Anasayfa";
import AdminProducts from "./admin/page/AdminProducts";
import AddProducts from "./admin/page/AddProducts";
import AProduct from "./admin/page/AProduct";
import YurtdisiShop from "./page/YurtdisiShop";
import YurticiShop from "./page/YurticiShop";
import Umre from "./page/Hizmetler/Umre";
import { Helmet } from "react-helmet-async";
import ScrollToTop from "./ScrolltoTop";
import TurTakvimi from "./page/TurTakvimi";
import GrupTalebi from "./page/GrupTalebi";

function App() {
  return (
    <>
      <Helmet>
        <title>Durlar Turizm</title>
        <meta
          name="description"
          content="Durlar Turizm Gezi ve Hac,Umre Turları"
        />
        <meta name="keywords" content="Hac,Umre,Turizm,Gezi" />
        <link rel="canonical" href="https://durlarturizm.com/" />
        <meta name="google-site-verification" content="878787878" />

        <meta name="generator" content="React" />
        <meta name="application-name" content="React" />
        <meta name="referrer" content="origin-when-cross-origin" />
        <meta name="creator" content="Aydtanitim.com" />
        <meta name="publisher" content="DurlarTurizm" />

        <meta property="og:title" content="DurlarTurizm" />
        <meta
          property="og:description"
          content="Durlar Turizm Gezi ve Hac,Umre Turları"
        />
        <meta property="og:url" content="https://durlarturizm.com/" />

        <meta property="og:site_name" content="DurlarTurizm" />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:title" content="DurlarTurizm" />
        <meta
          name="twitter:description"
          content="Durlar Turizm Gezi ve Hac,Umre Turları"
        />
        <meta name="twitter:url" content="https://durlarturizm.com/" />

        <meta name="twitter:site" content="@your_twitter_username" />
        <meta name="twitter:siteName" content="DurlarTurizm" />
        <meta name="twitter:creator" content="@your_twitter_username" />
        <meta name="twitter:locale" content="tr_TR" />
        <meta name="twitter:type" content="website" />
        <meta name="twitter:image" content="" />
      </Helmet>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop/yurtdisi" element={<YurtdisiShop />} />
          <Route path="/shop/yurtici" element={<YurticiShop />} />
          <Route path="/product/:slug" element={<ProductDetail />} />
          <Route path="/hizmetler/hac" element={<Hac />} />
          <Route path="/hizmetler/umre" element={<Umre />} />
          <Route path="/umreturlari" element={<UmreTurlari />} />
          <Route path="/turtakvimi" element={<TurTakvimi />} />
          <Route path="/gruptalebi" element={<GrupTalebi />} />

          <Route path="/iptal" element={<Iptal />} />
          <Route path="/gizlilik" element={<Gizlilik />} />
          <Route path="/hakkimizda" element={<Hakkımızda />} />
          <Route path="/iletisim" element={<Iletisim />} />

          <Route path="/admin" element={<Login />} />
          <Route path="/admin/anasayfa" element={<Anasayfa />} />
          <Route path="/admin/products" element={<AdminProducts />} />
          <Route path="/admin/products/add" element={<AddProducts />} />
          <Route path="/admin/product/:id" element={<AProduct />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
