import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import logo from "../../../assets/img/logo.png";
import { BsFacebook } from "react-icons/bs";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
const Header = () => {
  const [click, setClick] = useState(false);
  const [user, setUser] = useState({});

  const handlemenu = () => {
    if (click) {
      document.querySelector("#mobile-menu-offcanvas").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#mobile-menu-offcanvas").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };

  const closeMenu = () => {
    if (click) {
      document.querySelector("#mobile-menu-offcanvas").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#mobile-menu-offcanvas").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };
  // Sticky Menu Area
  useEffect(() => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 75
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
    window.addEventListener("scroll", isSticky);

    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 75
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      setUser(myuser);
    }
  }, [navigate]);
  return (
    <>
      <header className="header-section d-none d-xl-block tw-w-full tw-fixed tw-z-20 ">
        <div className="header-wrapper">
          <div className="header-bottom  section-fluid sticky-header">
            <div className="container">
              <div className="row">
                <div className="col-12 "></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row tw-border-b">
          <div className="col-2 d-flex align-items-end justify-content-center">
            <div className="header-logo">
              <div className="logo">
                <Link to="/">
                  <img
                    src={logo}
                    className="tw-w-[170px] tw-h-[120px]  tw-object-contain"
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-8 d-flex align-items-end justify-content-center">
            <div className="main-menu d-none d-xl-flex ">
              <nav>
                <ul>
                  <li className="has-dropdown">
                    <Link to={"/"} className="main-menu-link !tw-text-white">
                      ANA SAYFA
                    </Link>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      to={"/shop/yurtdisi"}
                      className="main-menu-link !tw-text-white"
                    >
                      YURTDIŞI TURLARI
                    </Link>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      to={"/shop/yurtici"}
                      className="main-menu-link !tw-text-white"
                    >
                      YURTİÇİ TURLARI
                    </Link>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      to={"/turtakvimi"}
                      className="main-menu-link !tw-text-white"
                    >
                      Tur Takvimi
                    </Link>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      to={"/gruptalebi"}
                      className="main-menu-link !tw-text-white"
                    >
                      GRUP TALEBİ
                    </Link>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      to={"/umreturlari"}
                      className="main-menu-link !tw-text-white"
                    >
                      UMRE TURLARI
                    </Link>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      to={"/iletisim"}
                      className="main-menu-link !tw-text-white"
                    >
                      İLETİŞİM
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-2 tw-flex tw-items-center tw-justify-center">
            <ul className="header-action-link action-color--black action-hover-color--golden">
              {user && user?.name === "admin" && (
                <li className="after_login">
                  <Link to="/admin/anasayfa">
                    <FaUserCircle className="tw-text-white" size={22} />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>

      <div className="mobile-header sticky-header tw-bg-teal-600 section-fluid d-lg-block d-xl-none">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="mobile-header-left">
                <ul className="mobile-menu-logo">
                  <li>
                    <Link to="/">
                      <div className="logo">
                        <img
                          src={logo}
                          className="tw-w-[240px] tw-h-[70px]  tw-object-contain"
                          alt="logo"
                        />
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="mobile-right-side">
                <ul className="header-action-link action-color--black action-hover-color--golden">
                  {user && user?.name === "admin" && (
                    <li className="after_login">
                      <Link to="/admin/anasayfa">
                        <FaUserCircle className="tw-text-white" size={22} />
                      </Link>
                    </li>
                  )}

                  <li>
                    <a
                      href="#!"
                      className="offcanvas-toggle offside-menu"
                      onClick={handlemenu}
                    >
                      <i className="fa fa-bars"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="mobile-menu-offcanvas"
        className="offcanvas offcanvas-rightside offcanvas-mobile-menu-section !tw-bg-teal-800"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handlemenu}>
            <MdClose size={32} color="white" />
          </button>
        </div>
        <div className="offcanvas-mobile-menu-wrapper">
          <div className="mobile-menu-bottom">
            <Link to="/">
              <div className="logo">
                <img
                  src={logo}
                  className="tw-w-[240px] tw-h-[70px]  tw-object-contain"
                  alt="logo"
                />
              </div>
            </Link>
            <div className="offcanvas-menu">
              <ul>
                <li>
                  <Link
                    to={"/"}
                    onClick={() => closeMenu()}
                    className="tw-italic"
                  >
                    ANASAYFA
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/shop/yurtdisi"}
                    onClick={() => closeMenu()}
                    className="tw-italic"
                  >
                    YURTDIŞI TURLARI
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/shop/yurtici"}
                    onClick={() => closeMenu()}
                    className="tw-italic"
                  >
                    YURTİÇİ TURLARI
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/umreturlari"}
                    onClick={() => closeMenu()}
                    className="tw-italic"
                  >
                    UMRE TURLARI
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/iletisim"}
                    onClick={() => closeMenu()}
                    className="tw-italic"
                  >
                    İLETİŞİM
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mobile-contact-info">
            <ul className="social-link">
              <li>
                <a href="https://www.facebook.com/duralturizm">
                  <BsFacebook size={28} color="white" />
                </a>
              </li>

              <li>
                <a href="https://www.youtube.com/@DuralTurizm">
                  <FaYoutubeSquare size={28} color="white" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/fatihdur.dural">
                  <FaInstagramSquare size={28} color="white" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
