import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross2 } from "react-icons/rx";
import img from "../../assets/img/common/epasaport.jpg";

const Buttonlar = () => {
  const [btn1, setBtn1] = useState(false);
  const [btn2, setBtn2] = useState(false);
  const [btn3, setBtn3] = useState(false);
  const [btn4, setBtn4] = useState(false);
  const [btn5, setBtn5] = useState(false);
  return (
    <>
      <div className="tw-flex tw-gap-3 tw-my-4 tw-overflow-x-scroll md:tw-overflow-x-hidden">
        <button
          className="tw-px-6 tw-py-2 tw-bg-red-600 tw-text-white tw-rounded-md"
          onClick={() => setBtn1(true)}
        >
          Ödeme ve İptal Şartları
        </button>
        <button
          className="tw-px-6 tw-py-2 tw-bg-red-600 tw-text-white tw-rounded-md"
          onClick={() => setBtn2(true)}
        >
          Önemli Notlar
        </button>
        <button
          className="tw-px-6 tw-py-2 tw-bg-red-600 tw-text-white tw-rounded-md"
          onClick={() => setBtn3(true)}
        >
          Umre ve Vize Evrakları
        </button>
        <button
          className="tw-px-6 tw-py-2 tw-bg-red-600 tw-text-white tw-rounded-md"
          onClick={() => setBtn4(true)}
        >
          Umre Sözleşmesi
        </button>
        <button
          className="tw-px-6 tw-py-2 tw-bg-red-600 tw-text-white tw-rounded-md"
          onClick={() => setBtn5(true)}
        >
          Çocuk Ücretleri
        </button>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={btn1}
        onHide={() => setBtn1(false)}
      >
        <Modal.Body>
          <div className="container tw-p-2">
            <div className="tw-flex tw-items-center tw-justify-between tw-py-2">
              <h2 className="tw-text-xl">Ödeme ve İptal Şartları</h2>
              <div
                className="tw-p-1 tw-bg-red-400 tw-rounded-md hover:tw-bg-red-500 tw-cursor-pointer"
                onClick={() => setBtn1(false)}
              >
                <RxCross2 size={28} color="gray" />
              </div>
            </div>
            <div className=" tw-border-b tw-border-black" />
            <ul className="tw-list-disc tw-ml-4 tw-my-8 tw-space-y-4 tw-text-sm">
              <li>
                Kayıt esnasında ödemenin %50 si kalan bakiye gitmeden 10 gün
                önce yapılacaktır.
              </li>
              <li>
                Vize sistemine girildikten sonra yapılan iptallerde 500 usd,
                uçak bileti alındıktan sonra yapılan iptallerde ise ilave olarak
                ilgili havayolunun uyguladığı cezai müeyyede uygulanır.
              </li>
              <li>
                Yolcu gittikten sonra herhangi bir sebeple erken dönmek isteyen
                umrecilerimize (otel, uçak, vize ve diğer hizmetler için
                ödemeler önceden yapıldığından) herhangi bir ücret iadesi
                yapılmaz.
              </li>
              <li>
                Yolcu gittikten sonra erken veya geç dönmek isterse buna
                kullanılan havayolunun uçaklarının müsaitlik durumuna göre ve
                kurallarına göre cevap verilir. Olabilecek ücret farkı yolcuya
                aittir.
              </li>
              <li className="tw-font-bold">
                Durlar Turizm ; Umre Programı kapsamında umre vizesi işlemleri
                sırasında, Ülkelerin Liman, Havaalanı, Havayolu veya benzer
                kurum ve kuruluşlarca tahsil edilen vergi, resim, harç ve
                benzeri yasal yükümlülüklerden meydana gelen değişikliklerden
                kaynaklanan ilave ek ücretleri Tur fiyatına yansıtma hakkını
                kendinde saklı tutar.
              </li>
            </ul>
            <h2 className="tw-font-bold">
              PROGRAMLARIMIZA DAHİL OLAN HİZMETLER
            </h2>
            <ul className="tw-list-disc tw-ml-4 tw-my-8 tw-space-y-4 tw-text-sm">
              <li>
                Belirtilen Hava Yolları ile ekonomik sınıfı tarifeli veya ilave
                sefer uçak bileti ücreti.
              </li>
              <li>Belirtilen otellerde belirtilen şekilde konaklama.</li>
              <li>
                Mekke-Medine/Medine-Mekke arası klimalı otobüslerle transfer.
              </li>
              <li>Vize, Diyanet Kartı ve Seyahat Sigortası.</li>
              <li>
                Tüm Havalimanı Otel Havalimanı transferleri ile Güleryüz ve
                farkını hissedeceğiniz İkram Turizm Rehberlik Hizmeti dahildir.
              </li>
              <li>
                Amman aktarmalı ve Kudüs Gezili Umre Programları Royal Jordan
                ile yapılmaktadır.
              </li>
            </ul>
            <h2 className="tw-text-lg tw-font-bold">ÇOCUK ÜCRETLERİ</h2>
            <ul className="tw-list-disc tw-ml-4 tw-my-8 tw-space-y-4 tw-text-sm">
              <li>00-02 Yaş Arası Çocuklar 450 Dolar</li>
              <li>02-06 Yaş Arası Çocuklar 950 Dolar</li>
              <li>06-11 Yaş Arası Çocuklar 950 Dolar</li>
              <li>
                Belirtilen ücretler, uçak bileti ve vize masrafı olup, anne ve
                babası ile aynı odada yataksız konaklamada geçerlidir.
              </li>
              <li>
                02-11 Yaş arası çocuklar aileler tarafından yatak talep
                edildiğinde normal tur programından 100 Dolar indirim yapılır.
              </li>
              <li>
                11 Yaşını bitirmiş çocuklar yetişkin sınıfına girmektedir.
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={btn2}
        onHide={() => setBtn2(false)}
      >
        <Modal.Body>
          <div className="container tw-p-2">
            <div className="tw-flex tw-items-center tw-justify-between tw-py-2">
              <h2 className="tw-text-xl">Önemli Notlar</h2>
              <div
                className="tw-p-1 tw-bg-red-400 tw-rounded-md hover:tw-bg-red-500 tw-cursor-pointer"
                onClick={() => setBtn2(false)}
              >
                <RxCross2 size={28} color="gray" />
              </div>
            </div>
            <div className=" tw-border-b tw-border-black" />

            <ul className="tw-list-disc tw-ml-4 tw-my-8 tw-space-y-4 tw-text-sm">
              <li>
                Kişi başı genellikle 30 kg uçakta yük hakkımız olup, fazla
                kilolardan firmamız sorumlu değildir.
              </li>
              <li>
                Gidiş ve dönüşlerde eşyaların kaybolması durumunda ve uçakların
                hava veya benzeri durumlarda yapmış oldukları rötarlardan
                havayolu sorumlu olup, şirketimiz gerekli ilgiyi gösterecektir.
              </li>
              <li>
                Otellerde oda buzdolabından kullanılan içeceklerden, yemeklerde
                alınan ekstra içecekler ve odanızdan yapacağınız yurt dışı
                telefon görüşmelerinden bu ve benzeri yapılan ekstra masraflar
                ücretlere dahil değildir.
              </li>
              <li>
                Uçuşlarımız tarifeli veya ekstra uçak seferleri ile
                yapılacaktır.
              </li>
              <li>
                Sağlık Hizmetleri Umre mevsiminde Suudi Arabistan’daki Devlet
                Hastanelerinde Umrecilere ücretsiz olarak verilmektedir. Yalnız
                misafirlerimizin devamlı kullandıkları ilaçlar var ise
                yanlarında götürmeleri gerekmektedir. Özel hastane ve poliklinik
                hizmetleri ücretlidir. Umrecilerimiz tarafından ödenecektir.
              </li>
              <li>
                Otel konaklamalarda uçağımız gece dahi olsa otele giriş gündüz
                saat 15:00 olarak kabul edilir. Gece uçuş gerçekleştirilmiş olup
                gece veya sabah otele giriş yapılsa bile 1 önceki gece
                konaklamaya dahildir. Otellerden çıkışımız ise 12:00’dir.
              </li>
              <li>
                Otel odaları genelde iki kişinin konaklaması için
                tasarlandığından, üçüncü kişi için eklenen yatak küçük ve daha
                az konforlu olacaktır.
              </li>
              <li>
                Kalkıştan en az 3 saat önce havalimanında olmak gerekmektedir.
              </li>
              <li>
                Otel mesafeleri Mekke’de Harem-i Şerife, Medine’de ise Mescid-i
                Nebevi’nin avluları baz alınarak yazılmıştır.
              </li>
              <li>
                Bazı uçuşlarımız netleşmediğinden belirtilen günlerde eksilme
                veya artma olabilir. Bu eksilme veya artma en fazla 1-2 gün
                ileri veya geri oynar.
              </li>
              <li>
                Gün artması durumunda firmamız her hangi bir ekstra ücret talep
                etmeyecek olup, eksilme durumunda ise program çerçevesinde az
                kalınan gecelemeler müşterilerimize ödenecektir.
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={btn3}
        onHide={() => setBtn3(false)}
      >
        <Modal.Body>
          <div className="container tw-p-2">
            <div className="tw-flex tw-items-center tw-justify-between tw-py-2">
              <h2 className="tw-text-xl">Umre Vizesi Evrakları</h2>
              <div
                className="tw-p-1 tw-bg-red-400 tw-rounded-md hover:tw-bg-red-500 tw-cursor-pointer"
                onClick={() => setBtn3(false)}
              >
                <RxCross2 size={28} color="gray" />
              </div>
            </div>
            <div className=" tw-border-b tw-border-black" />
            <h2 className="tw-font-bold">Vize İçin Gerekli Belgeler</h2>
            <img
              src={img}
              className="tw-w-[250px]  tw-object-contain"
              alt="passport"
            />

            <ul className="tw-list-disc tw-ml-4 tw-my-8 tw-space-y-4 tw-text-sm">
              <li>
                Uçuş tarihinden itibaren en az 7 ay geçerli ve ÇİPLİ Pasaport,
              </li>
              <li>1 adet vesikalık fotoğraf (Arka fon beyaz)</li>
              <li>
                45 yaşının altında eşi ile veya çocukları ile giden ailelerden
                nüfus müdürlüğünden alınmış vukuatlı nüfus kayıt örneği
              </li>
              <li>
                18 - 45 yaş aralığındaki yalnız bayanlar yanlarında birinci
                dereceden akrabası olmadan Suudi Arabistan kanunlarına göre umre
                vizesi verilmemektedir. Babası, kayınpederi, 18 yaşın üstünde
                erkek kardeşi, öz amcası veya öz dayısı ile gidebilirler. Bu
                kişiler için her ferdin ayrı ayrı nüfus müdürlüğünden alınmış
                vukuatlı nüfus kayıt örneklerinin asılları gerekmektedir.
              </li>
              <li>
                Çocukları ile giden ailelerden nüfus müdürlüğünden alınmış
                vukuatlı nüfus kayıt örneği,
              </li>
              <li>
                9 - 18 Yaş aralığında anne ve babası ile gitmeyecek olan
                çocuklar için yanlarında birinci dereceden akrabası olmadan
                Suudi Arabistan kanunlarına göre umre vizesi verilmemektedir.
                Yanlarında birinci dereceden akrabası olsa dahi vize garantisi
                verilmez. Bu kişiler için Noterden yaptırılacak olan
                Muvafakatname-Taahhütname ve her fert için ayrı ayrı nüfus
                müdürlüğünden alınmış vukuatlı nüfus kayıt örneklerinin asılları
                gerekmektedir.
              </li>
              <li>
                18 Yaşının altında anne ve babası ile gitmeyecek olan çocuklar
                için yanlarında birinci dereceden akrabası olmadan Suudi
                Arabistan kanunlarına göre kesinlikle Umre’ye gidemezler.
                Yanlarında birinci dereceden akrabası olsa bile vize garantisi
                verilmez. Bunlar için Noterden yaptırılacak olan
                Muvafakatname-Taahhütname ve her fert için ayrı ayrı nüfus
                müdürlüğünden alınmış vukuatlı nüfus kayıt örneklerinin asılları
                gerekmektedir. 45 yaşın üstünde bir anne 18 yaş altında kendi
                çocuğuna mahrem olamaz,
              </li>
              <li>
                0 - 8 yaş aralığındaki çocuklar yanlarında birinci dereceden
                akrabası olsa dahi annesiz kesinlikle umreye gidemezler.
              </li>
              <li>
                45 yaşın üstünde bir anne 45 yaş altındaki kızına ve 18 yaş
                altındaki kendi erkek çocuğuna mahrem olamaz
              </li>
              <li>
                Muvafakatname Örneği; Noterden ; Eşim, Kızım, kız kardeşim
                …………..babası, öz dayısı, öz amcası ………………… ile birlikte Umre ’ye
                gitmesine muvafakat ederim.
              </li>
              <li>
                Taahhütname Örneği; Noterden ; Kızım, gelinim, öz teyzem, öz
                halam ……………Umre ‘ye götürüp getireceğimi taahhüt ederim.
              </li>
              <li>
                VİZE İÇİN GEREKLİ TÜM BELGELERİN GİDİŞ TARİHİNDEN 20 GÜN ÖNCE
                TESLİM EDİLMESİ GEREKİR.
              </li>
              <li>
                45 Yaşın üstündeki bayan veya erkek ister tek veya eşi ile
                gitsin fark etmeksizin umre vizesi için sadece 1. ve 2. maddeler
                yeterlidir
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={btn4}
        onHide={() => setBtn4(false)}
      >
        <Modal.Body>
          <div className="container tw-p-2">
            <div className="tw-flex tw-items-center tw-justify-between tw-py-2">
              <h2 className="tw-text-xl">Umre Sözleşmesi</h2>
              <div
                className="tw-p-1 tw-bg-red-400 tw-rounded-md hover:tw-bg-red-500 tw-cursor-pointer"
                onClick={() => setBtn4(false)}
              >
                <RxCross2 size={28} color="gray" />
              </div>
            </div>
            <div className=" tw-border-b tw-border-black" />
            <h2 className="tw-font-bold">
              1. ACENTE'YE AİT HAK VE YÜKÜMLÜLÜKLER
            </h2>

            <ul className="tw-my-8 tw-space-y-4 tw-text-sm">
              <li>
                <strong className="tw-text-lg">{"A)VİZE:"}</strong> ACENTA Vize
                işlemlerinde Suudi Arabistan Büyükelçilik/Konsolosluk ile aracı
                konumundadır. Tüm evraklar tam olsa dahi Konsolosluk vize
                vermeyebilir. Vizeleri geç verebilir, buna bağlı seyahat
                gecikmeleri olabilir. Buradaki sorumluk ACENTA'ya ait değildir.
              </li>
              <li>
                <strong className="tw-text-lg">{"B)HAVAYOLU:"}</strong> ACENTE
                havayolu ile UMRECİ arasında aracı konumunda olup, 28/09/1955
                Lahey Protokolüne tabidir. Havayolu şirketleri tarafından
                yapılabilecek saat değişiklikleri ve olabilecek rötarlardan
                ACENTE sorumlu tutulamaz. Uçuşlarımız tarifeli ve/veya ekstra
                uçak seferleri ile özel ve/veya ulusal havayolları ile
                yapılabilir.
              </li>
              <li>
                <strong className="tw-text-lg">{"C)OTEL:"}</strong> Otele giriş
                saat 15:00'dir özellikle gece uçuşlarında, gece veya sabah otele
                giriş yapılırsa o gece konaklamaya dahildir. En geç otel
                çıkışımız 14:00'dir. 14:00 den sonra yapılacak çıkışlarda 1
                geceleme daha yapılmış sayılır. Özellikle 4-5 yıldızlı otel
                odaları genelde iki kişinin konaklaması için tasarlandığından,
                üçüncü kişi için eklenen yatak küçük ve daha az konforlu
                olacaktır. ACENTE kataloglarında belirtmiş olduğu Otel
                mesafeleri Mekke'de Harem-i Şerife, Medine'de ise Mescid-i
                Nebevi'nin avluları baz alınarak yazılmıştır. Bunu UMRECİ kabul
                ederek kaydını yaptırır.
              </li>
              <li>
                <strong className="tw-text-lg">{"D)TUR PROGRAMI:"}</strong>{" "}
                UMRECİ, ACENTE programlarına dahil olduktan sonra, vize kaynaklı
                gecikmelerden veya Suudi Arabistanca first majör sayılan otelin
                yıkım kararı çıkması ve veya kralın misafirlerinin
                rezervasyonları yapılması gibi konaklama otellerinde değişiklik
                durumunda ACENTE daima muadil otel katagorilerini baz alacaktır.
                ACENTE bu tip durumlarda UMRECİ'ye durmu bildirmekle yükümlüdür.
                ACENTE bu bildiriyi e-mail, SMS, fax veya telefon bildirimini
                yeterli kabul edilecektir. UMRECİ bu tip durumlarda arzu eder
                ise hiçbir masraf kesilmeden seyahatini iptal etme hakkını saklı
                tutar. Bu tip durumlarda ACENTE programı alt veya üst kategoriye
                alabilir. Bu durumlarda UMRECİ'nin tercihine göre, ACENTA üst
                kategori tercihinde ücret farkı talep eder, alt kategori
                tercihinde iade eder.
              </li>
              <li>
                <strong className="tw-text-lg">{"E)FESİH HAKLARI:"}</strong>{" "}
                ACENTE kendi iradesi dışında oluşan nedenlerle veya tura katılan
                kişilerin yeterli sayıya ulaşamamalarına bağlı olarak turları
                iptal etme hakkına sahiptir. Böyle bir durumda yolcuya yapılacak
                e-mail, SMS, fax veya telefon bildirimi yeterli kabul
                edilecektir. Burada müşterinin tazmin hakkı olamaz.
              </li>
              <li>
                <strong className="tw-text-lg">{"F)MÜCBİR SEBEPLER:"}</strong>{" "}
                ACENTE ile ulaşım firmaları arasında yapılan anlaşmalar gereği
                ulaşım araçlarının programlarda belirtilen saatlerde hareket
                yerlerinde bulunmayarak gezilerin vaktinde başlayamamaları
                gecikmeleri, gezilerin uygulanmaları sırasında OTOBÜS - UÇAK -
                VAPUR - TREN v.b lerin , gecikmeleri, arızalanmaları, kaza
                yapmaları şoförlerin vb. hastalanmaları, ölmeleri, vizelerinin
                alınamayışı, şahsi kusurları, Hava muhalefeti (sis, fırtına, kar
                yağışı, tipi, yıldırım düşmesi vb.)Doğal afetler (Deprem, sel,
                yangın, heyelan vb.)Halkhareketleri (terör, grev, lokavt,
                miting, yürüyüş, yol kapanması, savaş, kargaşa durumları,
                olağanüstü hal vb.) Sıkı yönetim, sokağa çıkma yasağı, seçimler
                vb.Sağlık sorunları (salgın hastalıklar, karantina vb.) gibi
                ACENTE kusuru bulunmayan her türlü olay ve KAZALARDAN ötürü ne
                şekilde olursa olsun ACENTE'i maddi ve manevi sorumluluk
                yüklenemez. Acente buraya ilave edilmeyen mücbir sebepler
                haklarını saklı tutar.
              </li>
            </ul>
            <h2 className="tw-font-bold">
              2. UMRECİYE AİT HAK VE YÜKÜMLÜLÜKLER
            </h2>
            <ul className="tw-my-8 tw-space-y-4 tw-text-sm">
              <li>
                <strong className="tw-text-lg">{"A)BİLGİ:"}</strong> UMRECİ
                katılmış olduğu tur programı hakkında detay bilgi alma hakkı
                vardır. Bu bilgiyi ACENTE vermek zorundadır.
              </li>
              <li>
                <strong className="tw-text-lg">{"B)PROGRAM:"}</strong> UMRECİ,
                ACENTA tarafından düzenlenen Mekke ve Medine ziyaretlerine, özel
                umre programlarına katılmayabilir. ACENTA düzenlenen tur ve
                programı otelde ilan etmek zorundadır. EVRAK: Müşteri seyahat
                tarihinden en az 20 gün veya daha fazla gün önce vize işlemleri
                için gerekli evrakları ACENTE ye teslim etmekle yükümlüdür. Bu
                evrakların gecikmesi ile oluşacak vize gecikmelerinden UMRECİ
                sorumludur.
              </li>
              <li>
                <strong className="tw-text-lg">{"C)ÜCRET:"}</strong> UMRECİ
                kayıt esnasında katılmış olduğu tur ücretinin %50'sini, uçuş
                programından 1 hafta öncede kalan bakiyesini ACENTE'ye ödemekle
                yükümlüdür.
              </li>
              <li>
                <strong className="tw-text-lg">{"D)HASTALIK:"}</strong> Suudi
                Arabistan'da veya bağlantılı noktaların ilgili duraklarında
                rahatsızlanan umrecilerin tedavi masrafları umrecİlere aittir.
                Suudi Arabistan Kraliyete ait hastanelerin uygulaması
                ücretsizdir. Özel hastane tedavileri ücretlidir. umrecilerin
                felç yatalak durumda olmaları halinde dönüş uçağında alınacak ek
                koltuk masrafları umreciye aittir. ACENTE, UMRECİ'yi tedavi için
                hastaneye götürmek ve haklarını anlatmak ve bilgilendirmekle
                yükümlüdür.
              </li>
              <li>
                <strong className="tw-text-lg">{"E)BAGAJ/VALİZ"}</strong> :
                Uçaklı seyahatlerde havayollarının açıklamış olduğu bagaj kg.
                ve/veya hacim hakkı vardır. Fazla bagaj ücretleri
                UMRECİ'lerimize aittir, bagajların gidiş ve dönüşte UMRECİ
                tarafından havayolu ve yerel otobüs firmalarında, konakladığı
                otelinde, Valizlerin ve içindeki eşyaların her türlü kaybından,
                hasara uğramasından, unutulmasından UMRECİ sorumludur ACENTE
                sorumlu değildir. ACENTE hiçbir suretle UMRECİ'nin eşyalarından
                sorumlu tutulamaz. .Valizlerin ve içindeki eşyanın her türlü
                sorumluluğu sahiplerine aittir. Kokan, akan, yanıcı veya
                patlayıcı vasıfları olan veya çevresine rahatsızlık veren
                eşyalar taşıt araçlarına alınmazlar, taşıyıcı firma kabul
                etmeyebilir, bundan ACENTE sorumlu tutulamaz.
              </li>
              <li>
                <strong className="tw-text-lg">{"UÇUŞ:"}</strong> Uçuş öncesinde
                saatler değişebilir UMRECİ uçuş saatini en az 48 saat önce
                ACENTA'dan teyid etmesi gerekmektedir. UMRECİ nin kalkıştan en
                az 3 saat önce havalimanında hazır olması gerekmektedir.
                UMRECİ'nin ikametinden veya bulunduğu yerden Gidiş ve dönüşlerde
                Türkiye sınırlarındaki hava alanı ulaşımları UMRECİ'ye aittir.
              </li>
              <li>
                <strong className="tw-text-lg">{"DÖNÜŞ:"}</strong> Yolcu
                gittikten sonra herhangi bir sebeple erken dönmek isteyen
                umrecilerimize (otel,uçak,vize ve diğer hizmetler için ödemeler
                önceden yapıldığından) herhangi bir ücret iadesi yapılmaz Yolcu
                gittikten sonra kendi bileti ile erken dönmek ister ise ancak
                buna kullanılan havayolunun uçakların müsaitlik durumuna göre
                olabilecektir. Bu erkenden dönüşten dolayı havayolunun talep
                ettiği ekstra bir fark olur ise yolcu bunu ödemeyi kabul eder.
                Kendi bileti buna müsait değil ise başka havayoluna ait bir
                dönüş bileti ile de arzu eder ise ücretini ödemek koşulu ile
                dönebilecektir. Bu ücretin şirketimizle hiçbir ilgisi yoktur.
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={btn5}
        onHide={() => setBtn5(false)}
      >
        <Modal.Body>
          <div className="container tw-p-2">
            <div className="tw-flex tw-items-center tw-justify-between tw-py-2">
              <h2 className="tw-text-xl">Çocuk Ücretleri</h2>
              <div
                className="tw-p-1 tw-bg-red-400 tw-rounded-md hover:tw-bg-red-500 tw-cursor-pointer"
                onClick={() => setBtn5(false)}
              >
                <RxCross2 size={28} color="gray" />
              </div>
            </div>
            <div className=" tw-border-b tw-border-black" />
            <ul className="tw-list-disc tw-ml-4 tw-my-8 tw-space-y-4 tw-text-sm">
              <li>00-02 Yaş Arası Çocuklar 450 Dolar</li>
              <li>02-06 Yaş Arası Çocuklar 950 Dolar</li>
              <li>06-11 Yaş Arası Çocuklar 950 Dolar</li>
              <li>
                Belirtilen ücretler, uçak bileti ve vize masrafı olup, anne ve
                babası ile aynı odada yataksız konaklamada geçerlidir.
              </li>
              <li>
                02-11 Yaş arası çocuklar aileler tarafından yatak talep
                edildiğinde normal tur programından 100 Dolar indirim yapılır.
              </li>
              <li>
                11 Yaşını bitirmiş çocuklar yetişkin sınıfına girmektedir.
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Buttonlar;
