import React from "react";
import logo from "../../assets/img/logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_URL } from "../../lib/config";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(API_URL + "/auth/login", data);
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        navigate("/admin/anasayfa");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) alert("Hata Oluştu");
    }
  };
  return (
    <div className="container">
      <div className="row tw-mt-32">
        <div className="col-12 d-flex justify-content-center">
          <img
            src={logo}
            className="tw-w-[220px] tw-h-[100px] tw-object-contain"
            alt="logo"
          />
        </div>
        <div className="col-12 tw-flex tw-flex-col tw-justify-center tw-items-center account_form">
          <h3 className="tw-mb-2 tw-font-bold tw-text-xl tw-px-4 tw-py-2 tw-rounded-full tw-shadow-md tw-border">
            Admin Girişi Yap
          </h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="default-form-box">
              <label>
                Kullanıcı Adı
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                {...register("name")}
                required
              />
            </div>
            <div className="default-form-box ">
              <label>
                Şifre
                <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                minLength="4"
                {...register("pass")}
                required
              />
            </div>
            <div className="login_submit tw-flex tw-gap-3">
              <button
                className="theme-btn-one btn-black-overlay btn_md tw-bg-black"
                type="submit"
              >
                Giriş
              </button>
              <button
                className="theme-btn-one btn-black-overlay btn_md tw-bg-black"
                type="button"
                onClick={() => {
                  localStorage.removeItem("user");
                  navigate("/");
                }}
              >
                Çıkış Yap
              </button>
            </div>
          </form>
        </div>
        <div className="col-12 d-flex justify-content-center"></div>
      </div>
    </div>
  );
};

export default Login;
