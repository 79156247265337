import React, { useEffect, useState } from "react";

const SideBar = (props) => {
  const { onFilterChange } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    window.innerWidth < 768 ? false : true
  );

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const [filter, setFilter] = useState({
    cat: ["Yurtiçi Turları"],
    tur: [],
    spec: [],
  });
  const turlar = [
    { name: "Yurtdışı Turları" },
    { name: "Karadeniz Bölgesi" },
    { name: "Doğu Anadolu Bölgesi" },
    { name: "İç Anadolu Bölgesi" },
    { name: "Ege Bölgesi" },
    { name: "Akdeniz Bölgesi" },
    { name: "Marmara Bölgesi" },
    { name: "Güneydoğu Anadolu Bölgesi" },
  ];

  const spec = [
    { name: "Gezi Turları" },
    { name: "Kaplıca Turları" },
    { name: "Günü Birlik Turlar" },
  ];

  useEffect(() => {
    onFilterChange({
      cat: filter.cat || [],
      tur: filter.tur || [],
      spec: filter.spec || [],
    });
  }, [onFilterChange, filter]);

  return (
    <>
      <div className="col-lg-3 ">
        <div className="shop_sidebar_wrapper">
          <div
            className="tw-flex md:tw-hidden tw-justify-center tw-items-center"
            onClick={toggleSidebar}
          >
            <span className="tw-border-t tw-border-solid tw-border-black tw-flex-grow" />
            <span className="tw-py-2 tw-px-5 tw-bg-black tw-text-white tw-rounded-full tw-flex tw-gap-3 tw-items-center">
              Sırala
              <i className="fa fa-caret-down"></i>
            </span>

            <span className="tw-border-t tw-border-solid tw-border-black tw-flex-grow " />
          </div>
          <div
            className={`shop_sidebar_boxed ${
              isSidebarOpen ? "tw-block" : "tw-hidden"
            }`}
          >
            <p className="tw-font-bold tw-px-4 tw-py-2 tw-rounded-full tw-bg-primary tw-shadow-lg tw-text-center tw-mb-4">
              KATEGORİLER
            </p>
            <label className="custom_boxed ">
              <input
                type="checkbox"
                name="category"
                className="tw-w-4 tw-h-4"
                checked={filter.cat.includes("Yurtdışı Turları")}
                onChange={() => {
                  setFilter((prev) => {
                    if (!prev.cat.includes("Yurtdışı Turları")) {
                      return {
                        ...prev,
                        cat: [...prev.cat, "Yurtdışı Turları"],
                      };
                    } else {
                      return {
                        ...prev,
                        cat: prev.cat.filter(
                          (item) => item !== "Yurtdışı Turları"
                        ),
                      };
                    }
                  });
                }}
              />
              <span>Yurtdışı Turları</span>
            </label>
            <label className="custom_boxed ">
              <input
                type="checkbox"
                name="category"
                className="tw-w-4 tw-h-4"
                checked={filter.cat.includes("Yurtiçi Turları")}
                onChange={() => {
                  setFilter((prev) => {
                    if (!prev.cat.includes("Yurtiçi Turları")) {
                      return {
                        ...prev,
                        cat: [...prev.cat, "Yurtiçi Turları"],
                      };
                    } else {
                      return {
                        ...prev,
                        cat: prev.cat.filter(
                          (item) => item !== "Yurtiçi Turları"
                        ),
                      };
                    }
                  });
                }}
              />
              <span>Yurtiçi Turları</span>
            </label>
          </div>
          <div
            className={`shop_sidebar_boxed ${
              isSidebarOpen ? "tw-block" : "tw-hidden"
            }`}
          >
            <p className="tw-font-bold tw-my-4 tw-px-4 tw-py-2 tw-rounded-full tw-bg-primary tw-shadow-lg tw-text-center tw-mb-4">
              GEZİ YERLERİ
            </p>

            <div className={`tw-max-h-[200px] tw-overflow-y-scroll `}>
              {turlar.map((data, index) => {
                return (
                  <label key={index} className="custom_boxed">
                    <input
                      type="checkbox"
                      name="turlar"
                      className="tw-w-4 tw-h-4"
                      onChange={() => {
                        setFilter((prev) => {
                          if (!prev.tur.includes(data.name)) {
                            return {
                              ...prev,
                              tur: [...prev.tur, data.name],
                            };
                          } else {
                            return {
                              ...prev,
                              tur: prev.tur.filter(
                                (item) => item !== data.name
                              ),
                            };
                          }
                        });
                      }}
                    />
                    <span>{data.name}</span>
                  </label>
                );
              })}
            </div>
          </div>
          <div
            className={`shop_sidebar_boxed ${
              isSidebarOpen ? "tw-block" : "tw-hidden"
            }`}
          >
            <p className="tw-font-bold tw-my-4 tw-px-4 tw-py-2 tw-rounded-full tw-bg-primary tw-shadow-lg tw-text-center tw-mb-4">
              TUR ÖZELLİĞİ
            </p>

            <div>
              {spec.map((data, index) => {
                return (
                  <label key={index} className="custom_boxed">
                    <input
                      type="checkbox"
                      name="ozellik"
                      className="tw-w-4 tw-h-4"
                      onChange={() => {
                        setFilter((prev) => {
                          if (!prev.spec.includes(data.name)) {
                            return {
                              ...prev,
                              spec: [...prev.spec, data.name],
                            };
                          } else {
                            return {
                              ...prev,
                              spec: prev.spec.filter(
                                (item) => item !== data.name
                              ),
                            };
                          }
                        });
                      }}
                    />
                    <span>{data.name}</span>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
