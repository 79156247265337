import React from "react";
import Header from "../component/Common/Header";
import Footer from "../component/Common/Footer";
import ProductDetails from "../component/Common/ProductDetail/ProductDetails";
import Banner from "../component/Common/Banner";

const ProductDetail = () => {
  return (
    <>
      <Header />
      <Banner />
      <ProductDetails />
      <Footer />
    </>
  );
};

export default ProductDetail;
