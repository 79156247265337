import React from "react";
import Header from "../component/Common/Header";
import Footer from "../component/Common/Footer";
import Banner from "../component/Home/Banner";
import TopProduct from "../component/Home/TopProduct";
import DealProduct from "../component/Home/DealProduct";

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <TopProduct />
      <DealProduct />
      <Footer />
    </>
  );
};

export default Home;
