import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
const HizmetSideBar = () => {
  const datenow = new Date();
  return (
    <div className="col-lg-4 ">
      <div className="tw-px-12 tw-flex tw-flex-col tw-justify-start">
        <h2 className="tw-text-3xl tw-font-semibold tw-text-[#695B4D] tw-mb-4">
          Hizmetlerimiz
        </h2>
        <Link to={"/hizmetler/hac"}>
          <div className="tw-w-full tw-cursor-pointer tw-border tw-rounded-r-full tw-px-6 tw-py-3 tw-flex tw-items-center tw-justify-between tw-mb-2 tw-text-[#695B4D]  hover:tw-text-white hover:tw-bg-red-600 tw-transition tw-duration-500 tw-ease-in-out">
            <p className=" tw-font-bold">
              {datenow?.getFullYear()} Hac Turumuz
            </p>
            <IoIosArrowForward size={18} color="black" />
          </div>
        </Link>
        <Link to={"/hizmetler/umre"}>
          <div className="tw-w-full tw-cursor-pointer tw-border tw-rounded-r-full tw-px-6 tw-py-3 tw-flex tw-items-center tw-justify-between tw-mb-2 tw-text-[#695B4D]  hover:tw-text-white hover:tw-bg-red-600 tw-transition tw-duration-500 tw-ease-in-out">
            <p className=" tw-font-bold">Umre Programlarımız</p>
            <IoIosArrowForward size={18} color="black" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HizmetSideBar;
