import Layout from "../component/Layout";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../lib/config";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import {
  useSort,
  HeaderCellSort,
  SortToggleType,
} from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/material-ui";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./tablecss.css";
const AdminProducts = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      if (myuser.name !== "admin" && myuser.role !== "ADMIN") navigate("/");
    } else {
      navigate("/");
    }
  }, [navigate]);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/form")
        .then((res) => {
          setProductData(res.data);
        })
        .catch((error) => {
          alert("Hata Oluştu");
        });
    };

    fetchData();
  }, []);

  const data = { nodes: productData };

  const materialTheme = getTheme(DEFAULT_OPTIONS);
  const theme = useTheme(materialTheme);

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: null,
        iconUp: <FaChevronUp />,
        iconDown: <FaChevronDown />,
      },
      sortToggleType: SortToggleType.AlternateWithReset,
      sortFns: {
        NAME: (array) => array.sort((a, b) => a?.name?.localeCompare(b?.name)),
        CAT: (array) =>
          array.sort((a, b) => a?.category?.localeCompare(b?.category)),
        SPEC: (array) => array.sort((a, b) => a?.spec?.localeCompare(b?.spec)),
        TARIH: (array) =>
          array.sort((a, b) => a?.tarih?.localeCompare(b?.tarih)),
      },
    }
  );

  function onSortChange(action, state) {
    //console.log(action, state);
  }
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  });
  function onPaginationChange(action, state) {}

  const totalPage = pagination?.state?.getTotalPages(data.nodes);

  const onDelete = async (id, name) => {
    // eslint-disable-next-line no-restricted-globals
    var result = confirm(
      name + " adlı Gezi silinecek. Devam etmek istiyor musunuz?"
    );
    if (result) {
      await axios
        .delete(`${API_URL}/form/${id}`)
        .then(() => {
          alert("Başarıyla Silindi");
          window.location.reload();
        })
        .catch((error) => {
          alert("Hata Oluştu");
        });
    } else {
      console.log("Silme işlemi iptal edildi.");
    }
  };

  return (
    <Layout>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="vendor_order_boxed pt-4">
            <div className="mb-2">
              <Link
                to="/admin/products/add"
                data-toggle="tab"
                className="theme-btn-one bg-black btn_sm "
              >
                Gezi Ekle
              </Link>
            </div>

            <div className="table-responsive">
              <Table
                data={data}
                sort={sort}
                theme={theme}
                pagination={pagination}
              >
                {(tableList) => (
                  <>
                    <Header>
                      <HeaderRow>
                        <HeaderCellSort>
                          <span className=" tw-text-gray-600 tw-text-center">
                            Resim
                          </span>
                        </HeaderCellSort>
                        <HeaderCellSort sortKey="NAME">
                          <span className="tw-text-sm tw-text-gray-600 tw-text-center">
                            Gezi Adı
                          </span>
                        </HeaderCellSort>
                        <HeaderCellSort sortKey="CAT">
                          <span className="tw-text-sm tw-text-gray-600 tw-text-center">
                            Kategori
                          </span>
                        </HeaderCellSort>
                        <HeaderCellSort sortKey="SPEC">
                          <span className="tw-text-sm tw-text-gray-600 tw-text-center">
                            Gezi Türü
                          </span>
                        </HeaderCellSort>
                        <HeaderCellSort sortKey="TARIH">
                          <span className="tw-text-sm tw-text-gray-600 tw-text-center">
                            Tarih
                          </span>
                        </HeaderCellSort>
                        <HeaderCellSort>
                          <span className="tw-text-sm tw-text-gray-600 tw-text-center">
                            Düzenle/Sil
                          </span>
                        </HeaderCellSort>
                      </HeaderRow>
                    </Header>

                    <Body>
                      {tableList?.map((item) => {
                        return (
                          <Row
                            key={item?._id}
                            className="hover:!tw-bg-slate-100 tw-cursor-pointer"
                            onClick={() =>
                              navigate(`/admin/product/${item?._id}`)
                            }
                          >
                            <Cell>
                              <Link to={`/admin/product/${item._id}`}>
                                <img
                                  width="120px"
                                  src={API_URL + item?.resim?.url1}
                                  className="tw-object-cover"
                                  alt="img"
                                />
                              </Link>
                            </Cell>
                            <Cell>
                              <Link to={`/admin/product/${item._id}`}>
                                {item?.name}
                              </Link>
                            </Cell>
                            <Cell>
                              <Link to={`/admin/product/${item._id}`}>
                                {item?.category}
                              </Link>
                            </Cell>
                            <Cell>
                              <Link to={`/admin/product/${item._id}`}>
                                {item?.spec}
                              </Link>
                            </Cell>
                            <Cell>
                              <Link to={`/admin/product/${item._id}`}>
                                {item?.tarih}
                              </Link>
                            </Cell>
                            <Cell>
                              <Link to={`/admin/product/${item?._id}`}>
                                <i className="fa fa-edit fa-lg"></i>
                              </Link>{" "}
                              <button
                                className="tw-text-red-700"
                                onClick={() => onDelete(item?._id, item?.name)}
                              >
                                <i className="fa fa-trash fa-lg"></i>
                              </button>
                            </Cell>
                          </Row>
                        );
                      })}
                    </Body>
                  </>
                )}
              </Table>
            </div>
            <div className="col-lg-12">
              <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-mb-12">
                <span className="tw-font-bold">Toplam Sayfa: {totalPage}</span>
                <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-mr-12">
                  <span className="tw-font-bold">
                    Sayfa : {pagination?.state?.page + 1}
                  </span>

                  <button
                    type="button"
                    className="theme-btn-one tw-p-1 tw-text-xl tw-bg-stone-200 "
                    disabled={pagination?.state?.page === 0}
                    onClick={() => pagination.fns.onSetPage(0)}
                  >
                    {"|<"}
                  </button>
                  <button
                    type="button"
                    className="theme-btn-one tw-p-1 tw-text-xl tw-bg-stone-200 "
                    disabled={pagination?.state?.page === 0}
                    onClick={() =>
                      pagination.fns.onSetPage(pagination.state.page - 1)
                    }
                  >
                    {"<"}
                  </button>
                  <button
                    type="button"
                    className="theme-btn-one tw-p-1 tw-text-xl tw-bg-stone-200 "
                    disabled={pagination?.state?.page + 1 === totalPage}
                    onClick={() =>
                      pagination.fns.onSetPage(pagination.state.page + 1)
                    }
                  >
                    {">"}
                  </button>
                  <button
                    type="button"
                    className="theme-btn-one tw-p-1 tw-text-xl tw-bg-stone-200 "
                    disabled={pagination?.state?.page + 1 === totalPage}
                    onClick={() => pagination.fns.onSetPage(totalPage - 1)}
                  >
                    {">|"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminProducts;
