import React, { useEffect, useState } from "react";
import Header from "../component/Common/Header";
import Footer from "../component/Common/Footer";
import Banner from "../component/Common/Banner";
import axios from "axios";
import { API_URL } from "../lib/config";
import { useForm } from "react-hook-form";

const GrupTalebi = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/form");
        setProducts(response.data);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, []);
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    await axios
      .post(`${API_URL}/basvuruform`, data)
      .then(() => {
        alert("Başarıyla Eklendi");
        window.location.reload();
      })
      .catch((error) => {
        alert("Hata Oluştu");
      });
  };
  return (
    <>
      <Header />
      <Banner />
      <section className="tw-px-2 tw-my-6">
        <div className="container">
          <div className="row">
            <div className="tw-text-2xl tw-font-bold tw-text-sky-500 tw-mb-6">
              GRUP TALEBİ
            </div>
          </div>
          <form className="add_product_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row tw-text-[#70767A]">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="tw-flex tw-flex-col">
                  <div className="tw-text-lg tw-underline tw-mb-4">
                    BAŞVURU FORMU
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="ad" className="tw-text-sm">
                          İsim
                        </label>
                        <input
                          type="text"
                          placeholder="Ad Soyad"
                          id="name"
                          className="form-control"
                          {...register("name")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="email" className="tw-text-sm">
                          E-Mail
                        </label>
                        <input
                          type="email"
                          id="email"
                          placeholder="Email Adresi"
                          className="form-control"
                          {...register("email")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="telefon" className="tw-text-sm">
                          Telefon
                        </label>
                        <input
                          type="text"
                          id="telefon"
                          placeholder="Telefon Numaranız"
                          className="form-control"
                          {...register("tel")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="kisi" className="tw-text-sm">
                          Kişi Sayısı
                        </label>
                        <input
                          type="number"
                          id="number"
                          placeholder="Kişi Sayısı"
                          className="form-control"
                          {...register("number")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="tur" className="tw-text-sm">
                          Tur
                        </label>
                        <select
                          name="category"
                          id="turlar"
                          {...register("turlar")}
                          className="tw-px-2"
                          required
                        >
                          {products?.map((item) => (
                            <option key={item._id} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="fotm-group">
                        <label htmlFor="not" className="tw-text-sm">
                          Mesaj
                        </label>
                        <textarea
                          rows="5"
                          className="form-control"
                          id="messages"
                          placeholder="Mesajınız varsa yazınız..."
                          {...register("not")}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="tw-flex tw-justify-start">
                        <button
                          type="submit"
                          className="theme-btn-one btn_md tw-bg-sky-600 tw-text-white"
                        >
                          Gönder
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="tw-flex tw-flex-col">
                  <div className="tw-text-lg tw-underline tw-mb-4">
                    Önemli Bilgiler
                  </div>
                  <p className="tw-text-sm tw-mb-2">
                    *Belirtmek istediğiniz hususları lütfen mesajla iletiniz...
                  </p>
                  <p className="tw-text-sm tw-mb-2">
                    *Grup Talebini doldurduktan sonra detaylı bilgi almak için
                    bizi lütfen (0332) 353 12 30 telefonumuzdan arayabilirsiniz.
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default GrupTalebi;
