import React, { useEffect, useState } from "react";
import ProductCard from "../../Common/Product/ProductCard";

import { API_URL } from "../../../lib/config";
import axios from "axios";

const TopProduct = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/form");
        setProducts(response.data);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, []);
  const yurtdisiProducts = products.filter(
    (product) => product.category === "Yurtdışı Turları"
  );
  const yurticiProducts = products.filter(
    (product) => product.category === "Yurtiçi Turları"
  );

  return (
    <>
      <section id="electronics_top_product" className="pb-100 tw-pt-20">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tw-text-2xl tw-font-bold">
                <h2>Yaklaşan Turlarımız</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tabs_right_button tw-flex tw-justify-end">
                <ul className="nav nav-tabs">
                  <li>
                    <a
                      data-toggle="tab"
                      href="#yurtici"
                      className="active tw-mr-4"
                    >
                      Yurtiçi Turları
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#yurtdisi">
                      Yurtdışı Turları
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs_el_wrapper">
                <div className="tab-content">
                  <div id="yurtici" className="tab-pane fade show in active">
                    <div className="row">
                      {yurticiProducts?.slice(0, 6).map((item) => (
                        <div
                          key={item?._id}
                          className="col-lg-4 col-md-6 col-sm-12 col-12"
                        >
                          <ProductCard data={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div id="yurtdisi" className="tab-pane fade ">
                    <div className="row">
                      {yurtdisiProducts?.slice(0, 6).map((item) => (
                        <div
                          key={item?._id}
                          className="col-lg-4 col-md-6 col-sm-12 col-12"
                        >
                          <ProductCard data={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopProduct;
