import React from "react";
import { Link, useLocation } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();
  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-2 col_xl_2">
        <div className="dashboard_tab_button">
          <ul role="tablist" className="nav flex-column dashboard-list">
            <li>
              <Link to="/">
                <i className="fa fa-tachometer"></i>Website
              </Link>
            </li>
            <li>
              <Link
                to="/admin/anasayfa"
                className={
                  location.pathname === "/admin/anasayfa" ? "active" : null
                }
              >
                <i className="fa fa-tachometer"></i>Anasayfa
              </Link>
            </li>
            <li>
              <Link
                to="/admin/products"
                className={
                  location.pathname === "/admin/products" ? "active" : null
                }
              >
                <i className="fa fa-shopping-cart"></i>Gezi Ayarları
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
