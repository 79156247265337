import React, { useEffect, useState } from "react";
import SideBar from "./DisSideBar";
import ProductCard from "../Common/Product/ProductCard";
import axios from "axios";
import { API_URL } from "../../lib/config";
import { Helmet } from "react-helmet-async";
import "./animate.css";
const DisShop = () => {
  const [products, setProducts] = useState([]);
  const [sort, setSort] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/form");
        setProducts(response.data);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, []);

  const [filterProducts, setFilterProducts] = useState([]);
  useEffect(() => {
    const filterMyProducts = () => {
      const selectedCat = filteredData?.cat || [];
      const selectedTur = filteredData?.tur || [];
      const selectedSpec = filteredData?.spec || [];

      return products.filter((product) => {
        const categoryMatch =
          selectedCat.length === 0 || selectedCat.includes(product?.category);

        const turMatch =
          selectedTur.length === 0 || selectedTur.includes(product?.location);

        const specMatch =
          selectedSpec.length === 0 || selectedSpec.includes(product?.spec);

        return categoryMatch && turMatch && specMatch;
      });
    };

    const sortMyProducts = (data) => {
      switch (sort) {
        case "low":
          return data.sort((a, b) => a?.name?.localeCompare(b?.name));
        case "high":
          return data.sort((a, b) => b?.name?.localeCompare(a?.name));
        default:
          return data;
      }
    };
    const filteredProducts = sortMyProducts(filterMyProducts());

    setFilterProducts(filteredProducts);
  }, [products, filteredData, sort]);

  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(filterProducts?.length / itemsPerPage);

  // Generate an array of page numbers
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  const generatePageGroups = () => {
    const pageGroups = [];
    for (let i = 0; i < totalPages; i += 5) {
      pageGroups.push(pageNumbers.slice(i, i + 5));
    }
    return pageGroups;
  };

  const pageGroups = generatePageGroups();
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const currentGroup = pageGroups[currentGroupIndex];

  const goToPreviousGroup = () => {
    setCurrentGroupIndex(Math.max(currentGroupIndex - 1, 0));
  };

  const goToNextGroup = () => {
    setCurrentGroupIndex(
      Math.min(currentGroupIndex + 1, pageGroups.length - 1)
    );
  };

  const calculateIndexRange = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return { startIndex, endIndex };
  };

  const { startIndex, endIndex } = calculateIndexRange();

  const pagiProducts = filterProducts.slice(startIndex, endIndex);

  return (
    <>
      <Helmet>
        <title>Yurtdışı Turları | Durlar Turizm</title>
        <meta name="description" content="Durlar Turizm Yurtdışı Turları" />
        <meta
          name="keywords"
          content="Turizm,Gezi,Tur,Turlar,Paris,Balkan,Fas,Mısır"
        />
        <link rel="canonical" href="http://localhost:3000/shop/yurtdisi" />

        <meta property="og:title" content={`Yurtdışı Turları | DurlarTurizm`} />
        <meta
          property="og:description"
          content="Durlar Turizm Yurtdışı Turları"
        />
        <meta property="og:url" href="http://localhost:3000/shop/yurtdisi" />

        <meta
          name="twitter:title"
          content={`Yurtdışı Turları | DurlarTurizm`}
        />
        <meta
          name="twitter:description"
          content="Durlar Turizm Yurtdışı Turları"
        />
        <meta name="twitter:url" href="http://localhost:3000/shop/yurtdisi" />
      </Helmet>
      <section
        id="shop_main_area"
        className="tw-py-16 plane_bg productPageHeroSlider"
      >
        <div className="container tw-bg-white">
          <div className="row">
            <SideBar onFilterChange={setFilteredData} />
            <div className="col-lg-9">
              <div className="row tw-justify-center md:tw-justify-end ">
                <div className="col-lg-7 tw-justify-start ">
                  <h1 className="tw-text-xl tw-font-extrabold">
                    YURTDIŞI TURLARI
                  </h1>
                </div>
                <div className="col-lg-5 product_shot">
                  <div className="product_shot_title ">
                    <p className="tw-text-sm">Sırala :</p>
                  </div>
                  <div className="customs_selects">
                    <select
                      name="product"
                      className="customs_sel_box"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="low">Ürün Adı (A - Z)</option>
                      <option value="high">Ürün Adı (Z - A)</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                {pagiProducts?.map((item) => {
                  return (
                    <div
                      key={item?._id}
                      className="col-lg-6 col-md-6 col-sm-12 col-12 "
                    >
                      <ProductCard data={item} />
                    </div>
                  );
                })}

                <div className="col-lg-12">
                  <ul className="pagination">
                    {pageNumbers?.length > 1 && (
                      <>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={goToPreviousGroup}
                            disabled={currentGroupIndex === 0}
                          >
                            &laquo;
                          </a>
                        </li>
                      </>
                    )}
                    {currentGroup?.map((pageNumber) => (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          page === pageNumber ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#!"
                          onClick={() => setPage(pageNumber)}
                        >
                          {pageNumber}
                        </a>
                      </li>
                    ))}
                    {pageNumbers?.length > 1 && (
                      <>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={goToNextGroup}
                            disabled={
                              currentGroupIndex === pageGroups.length - 1
                            }
                          >
                            &raquo;
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DisShop;
