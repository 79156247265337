import React from "react";
import ProductUpCard from "../../Common/Product/ProductUpCard";

const DealProduct = () => {
  const hacData = {
    url: "hac",
    name: "2023 HAC TURUMUZ",
  };
  const umreData = {
    url: "umre",
    name: "UMRE PROGRAMLARIMIZ",
  };
  return (
    <>
      <section id="elce_weekly_deal" className="tw-pb-12 slider_arrows_one">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="left_heading_three tw-text-4xl tw-font-extrabold textshadow">
                <h2>Aktif Hizmetlerimiz</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-12 col-12"></div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <ProductUpCard data={hacData} />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <ProductUpCard data={umreData} />
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12 col-12"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DealProduct;
