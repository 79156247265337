import React from "react";
import Header from "../../component/Common/Header";

import Footer from "../../component/Common/Footer";
import Banner from "../../component/Common/Banner";

const Gizlilik = () => {
  return (
    <>
      <Header />
      <Banner />
      <section id="gizlilik" className="tw-mt-12">
        <div className="container">
          <div className="tw-flex tw-flex-col tw-gap-2 tw-py-4 ">
            <h1 className="tw-text-xl tw-font-bold tw-mb-4">
              GİZLİLİK İLKELERİ
            </h1>
            <p className="tw-text-sm tw-mb-2">
              <strong>WEB SİTESİ GİZLİLİK SÖZLEŞMESİ</strong>
            </p>

            <p className="tw-text-sm ">
              Bu web sitesini ziyaret etmeniz ve bu site vasıtasıyla sunduğumuz
              hizmetlerden yararlanmanız sırasında size ve talep ettiğiniz
              hizmetlere ilişkin olarak elde ettiğimiz bilgilerin ne şekilde
              kullanılacağı ve korunacağı işbu "Gizlilik Politikası"nda
              belirtilen şartlara tabidir. Bu web sitesini ziyaret etmekle ve bu
              site vasıtasıyla sunduğumuz hizmetlerden yararlanmayı talep
              etmekle işbu "Gizlilik Politikası"nda belirtilen şartları kabul
              etmektesiniz.
            </p>
            <p className="tw-text-sm tw-mb-2">
              <strong>Bilgilerin Kullanılması ve Korunması</strong>
            </p>
            <p className="tw-text-sm ">
              <strong>Dural Turizm</strong> müşterilerine daha iyi hizmet
              verebilmek amacıyla bazı kişisel bilgilerinizi (isim, soy isim,
              yaş, e-posta, telefon vb…) sizlerden talep etmektedir.
            </p>
            <p className="tw-text-sm ">
              Toplanan bu kişisel bilgilerden elde edilebilecek istatistik
              veriler (tarayıcı tipi, coğrafi konum, yaş, cinsiyet, v.b.),
              dönemsel kampanya çalışmaları, e-bülten çalışmaları, müşteri
              profillerine yönelik özel promosyon faaliyetlerinin kurgulanması
              ve istenmeyen e-postaların iletilmemesine yönelik müşteri
              "sınıflandırma" çalışmalarında <strong>Dural Turizm</strong>{" "}
              bünyesinde kullanılmaktadır.
            </p>
            <p className="tw-text-sm ">
              <strong>Dural Turizm</strong> üyelik formlarından topladığı
              bilgileri, söz konusu üyenin haberi ya da aksi bir talimatı
              olmaksızın, üçüncü şahıslarla kesinlikle paylaşmamakta, faaliyet
              dışı hiçbir nedenle ticari amaçla kullanmamakta ve de
              satmamaktadır.
            </p>
            <p className="tw-text-sm ">
              <strong>Dural Turizm</strong> e-posta adresleri ve üyelik
              formlarında istediği kişisel bilgilerin haricinde site kullanımı
              sırasında izlediği, ziyaretçi hareket ve tercihlerini analiz
              ederek yorumlamaktadır. Kişisel bilgiler içermeyen bu
              istatistiksel veriler, misafirlere daha özel ve etkin bir
              rezervasyon deneyimi yaşatmak amacıyla kullanılmaktadır.
            </p>
            <p className="tw-text-sm ">
              Müşteri bilgileri, ancak resmi makamlarca usulü dairesinde bu
              bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat
              hükümleri gereğince resmi makamlara açıklama yapmak zorunda olduğu
              durumlarda resmi makamlara açıklanabilecektir.
            </p>
            <p className="tw-text-sm ">
              Müşterinin sisteme girdiği tüm bilgilere sadece Müşteri
              ulaşabilmekte ve bu bilgileri sadece Müşteri değiştirebilmektedir.
              Bir başkasının bu bilgilere ulaşması ve bunları değiştirmesi
              mümkün değildir.
            </p>
            <p className="tw-text-sm ">
              Ödeme sayfasında istenen kredi kartı bilgileriniz, siteden
              alışveriş yapan misafirlerimizin güvenliğini en üst seviyede
              tutmak amacıyla 3D Secure uygulaması ile alınmaktadır. Bu şekilde
              ödemeye yönelik tüm işlemlerin duralturizm.com ara yüzü üzerinden
              banka ve bilgisayarınız arasında gerçekleşmesi sağlanmaktadır.
              Ancak buna rağmen güvenlik ile ilgili konularda bir garanti
              verememektedir.
            </p>
            <p className="tw-text-sm ">
              İnternetin yapısı gereği bilgiler internet üzerinde yeterli
              güvenlik önlemleri olmasına rağmen dolaşabilir ve yetkili olmayan
              kişiler tarafından alınıp kullanılabilir. Bu kullanım ve
              kullanımdan doğacak zarar <strong>Dural Turizm</strong>{" "}
              sorumluluğunda değildir.
            </p>
            <p className="tw-text-sm ">
              Toplanan bilgiler genel kullanıma açık olmayan güvenli bir ortamda
              saklanır.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Gizlilik;
