import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../component/Layout";
import { API_URL } from "../../lib/config";
import axios from "axios";

const Anasayfa = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      if (myuser.name !== "admin" && myuser.role !== "ADMIN") navigate("/");
    } else {
      navigate("/");
    }
  }, [navigate]);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/basvuru")
        .then((res) => {
          setProductData(res.data);
        })
        .catch((error) => {
          alert("Hata Oluştu");
        });
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <div className="container">
        <div className="tw-text-xl tw-shadow-xl tw-font-extrabold tw-mb-4 tw-px-6 tw-py-3">
          BAŞVURULAR
        </div>
        <div className="tw-block tw-w-full tw-overflow-x-auto tw-bg-white tw-shadow-2xl">
          <table className="tw-items-center tw-w-full tw-bg-transparent tw-border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B] "
                  }
                >
                  Ad
                </th>
                <th
                  className={
                    "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B]"
                  }
                >
                  Email
                </th>
                <th
                  className={
                    "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B]"
                  }
                >
                  Telefon
                </th>
                <th
                  className={
                    "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B]"
                  }
                >
                  Tur
                </th>
                <th
                  className={
                    "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B]"
                  }
                >
                  Kişi
                </th>
                <th
                  className={
                    "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B]"
                  }
                >
                  Mesajı
                </th>
              </tr>
            </thead>
            <tbody>
              {productData.slice(0, 10).map((item) => (
                <tr key={item?._id}>
                  <th className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                    {item?.name}
                  </th>
                  <td className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                    {item?.email}
                  </td>
                  <td className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                    {item?.tel}
                  </td>
                  <td className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                    {item?.turlar}
                  </td>
                  <td className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                    {item?.number}
                  </td>

                  <td className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                    {item?.not}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Anasayfa;
