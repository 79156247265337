import React from "react";
import Header from "../../component/Common/Header";

import Footer from "../../component/Common/Footer";
import Banner from "../../component/Common/Banner";

const Hakkımızda = () => {
  return (
    <>
      <Header />
      <Banner />
      <section id="about-top" className="tw-mt-12">
        <div className="container">
          <div className="tw-flex tw-flex-col tw-gap-2 tw-py-4 ">
            <h1 className="tw-text-xl tw-font-bold">HAKKIMIZDA</h1>
            <p className="tw-text-sm tw-mb-4">
              Dural turizm 1997 yılında kurulmuş olup halen A 9745 belge
              numaralı Agrubu seyahat acentası belgesiyle faaliyetlerini
              sürdürmektedir. Şirketimiz hac ve umre alanında hizmetlerimiz
              devam etmektedir. Ayrıca okul taşımacılığı alanında devam
              etmektedir. Seyahatlerinizin bizimle gerçekleşmesini temenni eder,
              sağlık, sıhhat ve esenlikler dileriz.
            </p>
            <p className="tw-text-sm tw-mb-4">
              Saygıdeğer Dostlarımız Dural Turizm Seyahat Acentesı olarak
              kurulduğumuz günden bu yana yaklaşık 19 senedir turizm alanında
              sizlere hizmet etmeye devam ediyoruz. Hac ve umre ibadetlerimizin
              edasında verdiğimiz rehberlik hizmetlerinde gerekli azim ve
              gayreti göstermekteyiz. Hac ve umre menasilenin sünneti seniyye’ye
              uygun biçimde edası için dikkat ve itina ile hareket ediyoruz.
              Dural Turizm Seyahat Acentası olarak bu tutumu prensip aldığımız
              eğitim ve özellikle bu görev bize emanet edilirken, verile nasihat
              ve talimatlar istikametinde şekillenmiştir. Emaneti aldığımız o
              günden bugüne çerçeveyi aşmamaya, yaptığımız işin ciddiyetini hiç
              hatırdan çıkarmamaya azimi gayreti göstermekteyiz.
            </p>
            <p className="tw-text-sm tw-mb-4">
              Hac umre organizasyonlarında ve diğer inanç ve kültür turlarında,
              hep bir Ayet-i Kerimenin sihrine kapılarak gitmeye çalışıyoruz.
              Buyuruyor ki Cenab-ı hak; ”Ey resulüm de ki ; ” Yeryüzünde
              dolaşın. Sonrada, inkârcıların akıbeti nice olmuş bir
              görün”(En’am6/11) Bu ayeti kerimeden mülhem olarak diyoruz ki;
              Gezelim, Görelim, İbret Alalım…’’ Müminler olarak yaptığımız her
              işin ibadet aşkıyla yapılması gerektiğine inanıyor, işlerimizi bu
              anlayışla yapmaya çalışıyoruz. Zaman zaman çıkarmayı düşündüğümüz
              bu gibi bültenlerle hizmetlerimizi anlatmaya ve bu yolla da
              sizlere ulaşmaya çalışıyoruz.Desteğinizi bekliyor uyarı ve
              önerilerinizden yararlanmak istiyoruz. Cenab-ı hakk’ın haccımızı
              ve umremizi kabul buyurması niyaz ediyor, saygılar sunuyoruz.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Hakkımızda;
