import React from "react";
import Header from "../../component/Common/Header";

import Footer from "../../component/Common/Footer";
import Banner from "../../component/Common/Banner";

const Iletisim = () => {
  return (
    <>
      <Header />
      <Banner />
      <section id="about-top" className="tw-mt-12">
        <div className="container">
          <div className="tw-flex tw-flex-col tw-text-center tw-gap-2 tw-py-4 ">
            <h1 className="tw-text-3xl tw-font-extrabold">İLETİŞİM</h1>
            <h2 className="tw-text-xl tw-font-bold tw-mb-4">ADRES BİLGİSİ</h2>
            <p className="tw-mb-4">
              Aziziye Mh. Aziziye Cd. Acartürk İşhanı Kat: 2 No: 201
            </p>
            <p className="tw-mb-4">Telefon : (0332) 353 12 30</p>
            <p className="tw-mb-4">Email : info@duralturizm.com</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Iletisim;
