import React from "react";
import Header from "../../component/Common/Header";
import Banner from "../../component/Common/Banner";
import Footer from "../../component/Common/Footer";
import HizmetUmre from "../../component/Hizmetler/HizmetUmre";

const Umre = () => {
  return (
    <>
      <Header />
      <Banner />
      <HizmetUmre />
      <Footer />
    </>
  );
};

export default Umre;
