import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./imgstyle.css";
import { API_URL } from "../../../lib/config";
const ProductImage = (props) => {
  const { data } = props;
  const slider = useRef(null);

  const colorImages = [
    data?.url1 && data?.url1 !== null && API_URL + data?.url1,
    data?.url2 && data?.url2 !== null && API_URL + data?.url2,
    data?.url3 && data?.url3 !== null && API_URL + data?.url3,
    data?.url4 && data?.url4 !== null && API_URL + data?.url4,
  ].filter(Boolean);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow right-arrow" onClick={onClick}>
        <FaChevronRight />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow left-arrow" onClick={onClick}>
        <FaChevronLeft />
      </div>
    );
  };

  let slidersettings = {
    autoplay: true,
    arrows: true,
    dots: true,
    infinite: true,
    speed: 200,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    initialSlide: 0,
  };

  return (
    <div className="product_single_one_img tw-relative">
      <Slider {...slidersettings} ref={slider}>
        {colorImages?.map((item) => (
          <img
            src={item}
            className="tw-min-w-[600px] tw-object-contain"
            alt="Product"
          />
        ))}
      </Slider>
    </div>
  );
};

export default ProductImage;
