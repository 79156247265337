import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Banner = () => {
  let settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section id="electronics_banner">
        <div className="electronics_slider_box ">
          <Slider {...settings}>
            <div className="electronics_slider el_bg_1">
              <div className="container">
                <div className="row">
                  <div className="col-12 ">
                    <div className="electronics_slider_content">
                      <h5 className="textshadow tw-font-bold tw-text-zinc-700">
                        Durlar Turizm
                      </h5>
                      <h2>
                        <span>UMRE</span> Turları
                      </h2>
                      <h4 className="textshadow tw-font-bold tw-text-zinc-200">
                        Her anı maneviyatla yoğrulmuş manevi iklimin havasını
                        yaşamanız için her bütçeye uygun umre turları
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="electronics_slider el_bg_2">
              <div className="container">
                <div className="row">
                  <div className="col-12 ">
                    <div className="electronics_slider_content">
                      <h5 className="textshadow tw-font-bold tw-text-zinc-700">
                        Durlar Turizm
                      </h5>
                      <h2>
                        <span>HAC</span> Programları
                      </h2>
                      <h4 className="textshadow tw-font-bold tw-text-zinc-200">
                        Derin manevi deneyimlerle dolu, tarihi ve ruhani açıdan
                        önemli mekanları keşfedeceğiniz unutulmaz bir Haç Turu
                        için sizi bekliyoruz.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="electronics_slider el_bg_3">
              <div className="container">
                <div className="row">
                  <div className="col-12 ">
                    <div className="electronics_slider_content tw-h-44"></div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Banner;
