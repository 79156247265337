import React from "react";
import Header from "../component/Common/Header";
import Footer from "../component/Common/Footer";
import Banner from "../component/Common/Banner";
import DisShop from "../component/Shop/DisShop";

const YurtdisiShop = () => {
  return (
    <>
      <Header />
      <Banner />
      <DisShop />
      <Footer />
    </>
  );
};

export default YurtdisiShop;
