import React from "react";
import Header from "../../component/Common/Header";
import Banner from "../../component/Common/Banner";
import Footer from "../../component/Common/Footer";
import HizmetHac from "../../component/Hizmetler/HizmetHac";

const Hac = () => {
  return (
    <>
      <Header />
      <Banner />
      <HizmetHac />
      <Footer />
    </>
  );
};

export default Hac;
