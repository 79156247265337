import React from "react";
import Header from "../component/Common/Header";
import Footer from "../component/Common/Footer";
import Banner from "../component/Common/Banner";
import IcShop from "../component/Shop/IcShop";

const YurticiShop = () => {
  return (
    <>
      <Header />
      <Banner />
      <IcShop />
      <Footer />
    </>
  );
};

export default YurticiShop;
