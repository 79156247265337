import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../component/Layout";
import axios from "axios";
import { useForm } from "react-hook-form";
import { API_URL } from "../../lib/config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const AddProducts = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      if (myuser.name !== "admin" && myuser.role !== "ADMIN") navigate("/");
    } else {
      navigate("/");
    }
  }, [navigate]);

  const [imageOne, setImageOne] = useState();
  const [imageTwo, setImageTwo] = useState();
  const [imageThree, setImageThree] = useState();
  const [imageFour, setImageFour] = useState();
  const handleImageOne = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageOne(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleImageTwo = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageTwo(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleImageThree = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageThree(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleImageFour = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageFour(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const [quillValue, setQuillValue] = useState("");

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    if (quillValue === "") {
      alert("Açıklama Ekleyiniz");
      return;
    }

    const formData = new FormData();
    for (const key in data) {
      formData.append(encodeURIComponent(key), data[key]);
    }
    formData.append("quill", quillValue);

    await axios
      .post(`${API_URL}/addform`, formData)
      .then(() => {
        alert("Başarıyla Eklendi");
        window.location.reload();
      })
      .catch((error) => {
        alert("Hata Oluştu");
      });
  };
  const turlar = [
    { name: "Yurtdışı Turları" },
    { name: "Karadeniz Bölgesi" },
    { name: "Doğu Anadolu Bölgesi" },
    { name: "İç Anadolu Bölgesi" },
    { name: "Ege Bölgesi" },
    { name: "Akdeniz Bölgesi" },
    { name: "Marmara Bölgesi" },
    { name: "Güneydoğu Anadolu Bölgesi" },
  ];

  const ozellik = [
    { name: "Gezi Turları" },
    { name: "Kaplıca Turları" },
    { name: "Günü Birlik Turlar" },
  ];

  return (
    <Layout>
      <section id="add_product_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="add_product_wrapper">
                <h4>Gezi Ekle</h4>
                <form
                  className="add_product_form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="product_name">
                          Turun Adı<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="product_name"
                          className="form-control"
                          placeholder="Örn : MARMARA TURU"
                          {...register("name")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="product_desc">
                          Gidilecek Yerler<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="product_firma"
                          className="form-control"
                          placeholder="Örn : İstanbul - Edirne - Çanakkale - Bursa"
                          {...register("yerler")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="product_desc">
                          Tarih<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="product_tarih"
                          className="form-control"
                          placeholder="Örn : 09 - 14 NİSAN 2024"
                          {...register("tarih")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="fotm-group">
                        <label htmlFor="product_desc">
                          Günler<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="product_durum"
                          className="form-control"
                          placeholder="Örn : 5 Gece - 5 Gün"
                          {...register("gecegun")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fotm-group">
                        <label htmlFor="product_cat">
                          Kategori<span className="text-danger">*</span>
                        </label>
                        <select
                          name="category"
                          id="product_cat"
                          {...register("category", { required: true })}
                          required
                        >
                          <option value={"Yurtiçi Turları"}>
                            Yurtiçi Turları
                          </option>
                          <option value={"Yurtdışı Turları"}>
                            Yurtdışı Turları
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fotm-group">
                        <label htmlFor="product_cat">
                          Yeri<span className="text-danger">*</span>
                        </label>
                        <select
                          name="category"
                          id="product_cat"
                          {...register("location", { required: true })}
                          required
                        >
                          {turlar?.map((item, index) => (
                            <option key={index} value={item?.name}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fotm-group">
                        <label htmlFor="product_cat">
                          Özellik<span className="text-danger">*</span>
                        </label>
                        <select
                          name="category"
                          id="product_spec"
                          {...register("spec", { required: true })}
                          required
                        >
                          {ozellik?.map((item, index) => (
                            <option key={index} value={item?.name}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row tw-my-4">
                        <div className="col-lg-3 tw-border tw-border-black tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3">
                          <span>Resim-1</span>
                          <img
                            src={imageOne}
                            className="tw-w-[120px] tw-h-[120px] tw-object-contain"
                            alt="img1"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            className="tw-max-w-60"
                            required
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const maxSize = 3 * 1024 * 1024;

                              if (file.size > maxSize) {
                                alert("Dosya boyutu 3MB'dan fazla olamaz.");
                                e.target.value = "";
                              } else {
                                handleImageOne(e);
                                register(`ImageOne`, {
                                  shouldUnregister: true,
                                  value: file,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3 tw-border tw-border-black tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3">
                          <span>Resim-2</span>
                          <img
                            src={imageTwo}
                            className="tw-w-[120px] tw-h-[120px] tw-object-contain"
                            alt="img1"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            className="tw-max-w-60"
                            required
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const maxSize = 3 * 1024 * 1024;

                              if (file.size > maxSize) {
                                alert("Dosya boyutu 3MB'dan fazla olamaz.");
                                e.target.value = "";
                              } else {
                                handleImageTwo(e);
                                register(`ImageTwo`, {
                                  shouldUnregister: true,
                                  value: file,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3 tw-border tw-border-black tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3">
                          <span>Resim-3</span>
                          <img
                            src={imageThree}
                            className="tw-w-[120px] tw-h-[120px] tw-object-contain"
                            alt="img1"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            className="tw-max-w-60"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const maxSize = 3 * 1024 * 1024;

                              if (file.size > maxSize) {
                                alert("Dosya boyutu 3MB'dan fazla olamaz.");
                                e.target.value = "";
                              } else {
                                handleImageThree(e);
                                register(`ImageThree`, {
                                  shouldUnregister: true,
                                  value: file,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3 tw-border tw-border-black tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3">
                          <span>Resim-4</span>
                          <img
                            src={imageFour}
                            className="tw-w-[120px] tw-h-[120px] tw-object-contain"
                            alt="img1"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            className="tw-max-w-60"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              const maxSize = 3 * 1024 * 1024;

                              if (file.size > maxSize) {
                                alert("Dosya boyutu 3MB'dan fazla olamaz.");
                                e.target.value = "";
                              } else {
                                handleImageFour(e);
                                register(`ImageFour`, {
                                  shouldUnregister: true,
                                  value: file,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <ReactQuill
                        theme="snow"
                        value={quillValue}
                        onChange={setQuillValue}
                        modules={{
                          toolbar: [
                            [{ font: [] }],
                            [{ size: [] }],
                            [{ color: [] }, { background: [] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],

                            ["link", "image", "video"],
                            ["clean"],
                          ],
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <div className="btn_right_table">
                        <button
                          type="submit"
                          className="theme-btn-one  btn_sm tw-bg-black tw-text-white"
                        >
                          Gezi Ekle
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddProducts;
