import React from "react";
import Header from "../../component/Common/Header";
import Banner from "../../component/Common/Banner";
import Footer from "../../component/Common/Footer";

const Iptal = () => {
  return (
    <>
      <Header />
      <Banner />
      <section id="iptal" className="tw-mt-12">
        <div className="container">
          <div className="tw-flex tw-flex-col tw-gap-2 tw-py-4 ">
            <h1 className="tw-text-xl tw-font-bold tw-mb-4">
              İPTAL VE İADE ŞARTLARI
            </h1>
            <p className="tw-text-sm tw-mb-2">
              <strong>5. İPTAL VE DİĞER HÜKÜMLER</strong>
            </p>
            <p className="tw-text-sm ">
              <strong>5.1. </strong>Müşterinin şikayetçi olduğu hususları
              hizmetin ifası sırasında yazılı olarak yetkiliye bildirmesi iyi
              niyetli Müşterinin özen borcudur. Müşteri şikayetçi olduğu halde
              hizmeti acente yetkililerine bildirmeden sonuna kadar kullanması,
              şikayet konuları ile ilgili ikame hizmet ve bedel iadesi gibi
              tazminat haklarını ortadan kaldırır.
            </p>
            <p className="tw-text-sm ">
              <strong>5.2. </strong>Müşteri dilerse konaklama hizmeti başlamadan
              72 saat öncesine kadar rezervasyonunu iptal etmek üzere
              sigortalatabilir. İptal sigortası satın alan müşterilerin, hasar
              zarar ve kayıpları ile ilgili teminatın kapsamı bu hizmeti sunan
              sigorta firmasının poliçesiyle belirlenmiştir. Acenta bu
              teminatların içeriği, kapsamları veya uygulama şekilleri ile
              ilgili herhangi bir sorumluluk taşımamaktadır.
            </p>
            <p className="tw-text-sm ">
              <strong>5.3. </strong>Müşteri bu rezervasyon sözleşmesini, mail
              order, sanal pos, havale veya eft ile alarak her hangi bir
              sebepten dolayı imzalayamamış olsa dahi taraflar arasında geçerli
              olacak bu sözleşme şartlarını katalog, internet sitesi veya
              ilanlar ile öğrenmiş olup, bu rezervasyon sözleşmesini bu
              sözleşmedeki yazılı şartlarda almayı taahhüt etmişlerdir.
            </p>
            <p className="tw-text-sm ">
              <strong>5.4. </strong>Müşteri bu rezervasyon ile imza altına
              alınan ve sözleşmede adı geçen otel hakkında her türlü bilgiyi
              gerek Acenta'ya ait web adreslerinden ve şirket satış
              temsilcilerinden aldıktan ve gerekli tüm incelemesini yaptıktan
              sonra bu sözleşmeyi okuyarak imzaladığını taahhüt eder. Acenta,
              rezervasyon yapmayı vaat ettiği konaklama tesisinin vasıfları ve
              yıldızlaması ile, konaklama tesisinde öngörülen hizmetler ile
              ilgili bilgiyi, konaklama tesisinin beyanına, broşürlerine ve
              tesisin resmi internet sitesindeki bilgilerine dayanarak ilan
              etmiş olup, konaklama tesisinin, ilan ve deklarasyonlarında yer
              alan evsafta ve yıldızlama standartlarında hazır
              bulundurulmamasından dolayı, acentanın mesuliyeti yoktur.
              Sorumluluk münhasıran konaklama tesisinin mesuliyetidir. Konaklama
              tesisinin öngörülen standartları taşımaması veya konaklamanın
              başlayacağı tarihte bu standartta hizmet verebilecek durumda
              olmaması halinde, acenta müşteriyi aynı standartda veya bir üst
              kategoride olan bir tesisinde konaklatmak üzere, rezervasyon ve
              konaklatmak hakkına haizdir. Müşteri böyle bir değişikliği kabul
              etmez ise rezervasyonu için ödediği toplam bedeli geriye alma
              hakkına sahiptir.
            </p>
            <p className="tw-text-sm ">
              <strong>5.5. </strong>İptal edilmeksizin otele giriş yapılmaması
              halinde konaklama bedeli iade edilmez. Ölüm, hastalık kaza gibi
              durumlarda mücbir sebebin belgelenmesi halinde kalınmayan
              sürelerin bedelleri iade edilir. Bu sebeplerin dışındaki herhangi
              bir nedenle otele geç giriş yapılması veya erken çıkış yapılması
              halinde kalan sürelerin bedelleri iade edilmez.
            </p>
            <p className="tw-text-sm ">
              <strong>5.6. </strong>Tesise girişte kimlik ve yaş kontrolü
              yapılmaktadır. Yanlış veya eksik beyan halindeki bildirimlerden
              dolayı arada fark doğar ise otele giriş anında tahsil edilir. Bu
              farktan acenta sorumlu değildir.
            </p>
            <p className="tw-text-sm ">
              <strong>5.7. </strong>Erken rezervasyon dönemi içerisinde madde
              3.G'de yazılı olan süreç geçerli olmayıp; iptal, değişiklik ve
              devir halleri sadece iptal sigortası olan müşterilere
              uygulanmaktadır.
            </p>
            <p className="tw-text-sm ">
              <strong>5.8. </strong>Kayıt anında rezervasyon bedelinin en az
              %35'i, kalan bakiyenin ise hizmetin başlamasından en geç 15 gün
              önce ödenmesi gerekmektedir. Anılan süreler içinde belirtilen
              ödemelerin gerçekleştirilmemesi halinde yapılan rezervasyon iptal
              edilerek hizmet bedelinin %35'i cayma tazminatı olarak tüketiciye
              fatura edilir. Alınmış olan hizmet kampanya dahilinde bir ürün ise
              bu madde geçerli değildir.
            </p>
            <p className="tw-text-sm ">
              <strong>5.9. </strong>Erken rezervasyon dönemi ve/veya tüm
              dönemlere ait (sistem kaynaklı yanlışlıklar haricinde) fiyat
              artışlarında ilave olarak aradaki fark müşteriden talep edilmediği
              gibi tesis tarafından aksiyon olarak adlandırılan indirimlerde de
              iadesi söz konusu değildir.
            </p>
            <p className="tw-text-sm ">
              <strong>5.10. </strong>Seyahat sigortası hizmeti satın alan
              tüketicilerin eksik veya ayıplı ifa , hasar zarar ve kayıpları ile
              ilgili teminatın kapsamı bu hizmeti sunan sigorta firmasının
              poliçesiyle belirlenmiştir. Acenta bu teminatların içeriği,
              kapsamları veya uygulama şekilleri ile ilgili herhangi bir
              sorumluluk taşımamaktadır.
            </p>
            <p className="tw-text-sm ">
              <strong>5.11. </strong>İş bu protokol iki nüsna halinde tanzim ile
              beraberce imzalanmış olup, protokolden kaynaklanacak munazaralarda
              taraflar munhasiran TÜRSAB Turizm Tüketicileri Talepleri
              Değerlendirme Çizelgesi maddelerini kabul ederler.
            </p>
            <p className="tw-text-sm ">
              <strong>5.12. </strong>Müşterinin konaklama başlangıcından sonra
              gelen erken çıkış talebini yazılı, şikâyete sebep, mücbir sebepler
              de dâhil olarak yapması ile ilgili tur sağlayıcının onayı
              doğrultusunda değerlendirilerek yapılır. Erken ayrılma halinde,
              ücret iadesinin yapılıp yapılmayacağı işbirliği yapılan
              sağlayıcının inisiyatifinde olduğundan acenteye bu konuda bir
              taleple itiraz yöneltilemez ve uygulanacak olan herhangi bir cezai
              işlem konaklama bedeli olarak fatura edilir.
            </p>
            <p className="tw-text-sm ">
              <strong>5.13. </strong>Müşteri otelle ilgili tüm
              memnuniyetsizliklerini öncelikle otele bildirmek, akabinde açacağı
              dava ve yapacağı şikayetlerde oteli karşı taraf olarak göstermek
              zorundadır. Hiçbir şekilde maddi, manevi tazminat vb. taleplerini
              acenta üzerinden yapamaz.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Iptal;
