import React from "react";
import { BsFacebook } from "react-icons/bs";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
  const datenow = new Date();
  return (
    <>
      <footer
        id="footer_one"
        style={{
          backgroundImage: `url(${require("../../../assets/img/common/footer.jpg")})`,
        }}
        className="tw-py-16"
      >
        <div className="container">
          <div className="row ">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 tw-border-r tw-mb-10 md:tw-mb-0 ">
              <div className="footer_one_widget tw-text-white tw-text-center tw-text-sm">
                <div className="tw-text-white tw-pb-4 tw-text-lg">
                  ADRES BİLGİSİ
                </div>
                <ul>
                  <li className="tw-mb-4">
                    Aziziye Mh. Aziziye Cd. Acartürk İşhanı Kat: 2 No: 201
                  </li>
                  <li className="tw-mb-4">Telefon : (0332) 353 12 30</li>
                  <li className="tw-mb-4">Email : info@duralturizm.com</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 tw-border-r tw-mb-10 md:tw-mb-0 ">
              <div className="footer_one_widget tw-text-white tw-text-center tw-text-sm">
                <div className="tw-text-white tw-pb-4 tw-text-lg">BİLGİLER</div>
                <div className="tw-flex tw-text-nowrap tw-flex-wrap tw-items-center tw-justify-center">
                  <Link to={"/iptal"}>
                    <span className="tw-border-r tw-px-2">
                      İptal ve İade Şartları
                    </span>
                  </Link>
                  <Link to={"/gizlilik"}>
                    <span className="tw-border-r tw-px-2">
                      Gizlilik İlkeleri
                    </span>
                  </Link>
                  <Link to={"/hakkimizda"}>
                    <span className="tw-border-r tw-px-2">Hakkımızda</span>
                  </Link>
                  <Link to={"/iletisim"}>
                    <span className=" tw-px-2">İletişim</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 ">
              <div className="footer_one_widget tw-text-white tw-text-center tw-text-sm">
                <div className="tw-text-white tw-pb-4 tw-text-lg">
                  KISACA BİZ
                </div>
                <p className="tw-text-xs tw-font-light">
                  Dural turizm 1997 yılında kurulmuş olup halen A 9745 belge
                  numaralı Agrubu seyahat acentası belgesiyle faaliyetlerini
                  sürdürmektedir. Şirketimiz hac ve umre alanında hizmetlerimiz
                  devam etmektedir. Ayrıca okul taşımacılığı alanında devam
                  etmektedir. Seyahatlerinizin bizimle gerçekleşmesini temenni
                  eder, sağlık, sıhhat ve esenlikler dileriz.
                </p>
              </div>
            </div>
          </div>
          <div className="row tw-pt-10 md:tw-pt-24 tw-pb-12">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 tw-relative">
              <div className="tw-flex tw-justify-center ">
                <div className="tw-z-10 tw-bg-slate-100 tw-flex tw-gap-3 tw-px-4 tw-py-2 tw-rounded-full">
                  <a href="https://www.facebook.com/duralturizm">
                    <BsFacebook size={28} color="black" />
                  </a>
                  <a href="https://www.youtube.com/@DuralTurizm">
                    <FaYoutubeSquare size={28} color="black" />
                  </a>
                  <a href="https://www.instagram.com/fatihdur.dural">
                    <FaInstagramSquare size={28} color="black" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row tw-pt-4">
            <div className="col-lg-12 col-md-6 col-sm-6 col-12">
              <div className="copyright_left">
                <div className="tw-text-white">
                  © CopyRight {datenow?.getFullYear()}{" "}
                  <a href="https://www.aydtanitim.com/">
                    <span>AYDTanıtım</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
