import React from "react";
import HizmetSideBar from "./HizmetSideBar";
import img from "../../assets/img/common/hac.webp";

const HizmetHac = () => {
  const datenow = new Date();
  return (
    <section id="shop_main_area" className="tw-py-16">
      <div className="container">
        <div className="row">
          <HizmetSideBar />
          <div className="col-lg-8">
            <div className="tw-flex tw-items-center tw-justify-center">
              <img
                src={img}
                className="tw-w-[600px]  tw-object-contain"
                alt="Kabe"
              />
            </div>
            <div className="tw-mt-6">
              <h1 className="tw-text-3xl tw-text-[#695B4D] tw-mb-4 tw-font-bold">
                {datenow?.getFullYear()} HAC TURUMUZ
              </h1>
              <p className="tw-text-sm">
                Dural turizm 1997 yılında kurulmuş olup halen A 9745 belge
                numaralı Agrubu seyahat acentası belgesiyle faaliyetlerini
                sürdürmektedir. Şirketimiz hac ve umre alanında hizmetlerimiz
                devam etmektedir. Ayrıca okul taşımacılığı alanında devam
                etmektedir. Seyahatlerinizin bizimle gerçekleşmesini temenni
                eder, sağlık, sıhhat ve esenlikler dileriz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HizmetHac;
