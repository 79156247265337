import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductImage from "./ProductImage";
import axios from "axios";
import { API_URL } from "../../../lib/config";
import { Helmet } from "react-helmet-async";
const ProductDetails = () => {
  let { slug } = useParams();
  const [product, setProduct] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/aform/" + slug);
        setProduct(response.data);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, [slug]);

  return (
    <>
      <Helmet>
        <title>{`${product?.name}`} | DurlarTurizm</title>
        <meta
          name="description"
          content="Durlar Turizm Gezi ve Hac,Umre Turları"
        />
        <meta name="keywords" content="Hac,Umre,Turizm,Gezi" />
        <link
          rel="canonical"
          href={`https://durlarturizm.com/product/${product?.slug}`}
        />

        <meta property="og:title" content={`${product?.name} DurlarTurizm`} />
        <meta
          property="og:description"
          content="Durlar Turizm Gezi ve Hac,Umre Turları"
        />
        <meta
          property="og:url"
          href={`https://durlarturizm.com/product/${product?.slug}`}
        />
        <meta
          property="og:image"
          content={`${API_URL}${product?.resim?.url1}`}
        />
        <meta name="twitter:title" content={`${product?.name} Nilrio`} />
        <meta name="twitter:description" content={product?.desc} />
        <meta
          name="twitter:url"
          content={`https://durlarturizm.com/product/${product?.slug}`}
        />
        <meta
          name="twitter:image"
          content={`${API_URL}${product?.resim?.url1}`}
        />
      </Helmet>
      <section id="product_single_one" className="ptb-100">
        <div className="container">
          <div className="row area_boxed">
            <div className="col-lg-8">
              <ProductImage data={product?.resim} />
            </div>
            <div className="col-lg-4">
              <div className="product_details_right_one">
                <div className="modal_product_content_one tw-mt-8">
                  <h1 className="tw-text-3xl tw-font-extrabold tw-text-red-700 tw-mb-4">
                    {product?.name}
                  </h1>
                  <div className=" tw-border-b-[1px] tw-border-gray-400 tw-w-full md:tw-w-[350px] tw-mb-2" />
                  <div className="tw-mt-8 tw-flex tw-flex-col tw-gap-3 tw-mb-2">
                    <h2 className="tw-text-xl tw-font-bold">
                      {product?.yerler}
                    </h2>
                    <h2 className="tw-text-xl tw-text-red-800 tw-font-bold">
                      {product?.tarih}
                    </h2>
                    <h2 className="tw-text-xl tw-text-red-800 tw-font-bold">
                      {product?.gecegun}
                    </h2>
                  </div>

                  <div className=" tw-border-b-[1px] tw-border-gray-400 tw-w-full md:tw-w-[350px] tw-my-4" />
                </div>
              </div>
            </div>
          </div>
          <div className="row tw-mt-20">
            <div className="col-12 tw-flex tw-justify-center">
              <p className="tw-text-primary tw-border tw-border-primary tw-shadow-xl tw-px-10 tw-py-4 tw-rounded-full  ">
                AÇIKLAMA
              </p>
            </div>
            <div className="col-12 tw-mt-12 tw-flex tw-items-center tw-justify-center">
              <div className="tw-border tw-border-black">
                <div
                  className="tw-p-3"
                  dangerouslySetInnerHTML={{ __html: product.quill }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
