import React, { useEffect, useState } from "react";
import Header from "../component/Common/Header";
import Footer from "../component/Common/Footer";
import Banner from "../component/Common/Banner";
import axios from "axios";
import { API_URL } from "../lib/config";
import { Link } from "react-router-dom";

const TurTakvimi = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/form");
        setProducts(response.data);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <section className="tw-px-2 tw-my-6">
        <div className="container">
          <div className="row">
            <div className="tw-text-2xl tw-font-bold tw-text-sky-500 tw-mb-6">
              TUR TAKVİMİ
            </div>
          </div>
          <div className="row">
            <div className=" col-12">
              <div className="tw-block tw-w-full tw-overflow-x-auto tw-bg-white tw-shadow-2xl">
                <table className="tw-items-center tw-w-full tw-bg-transparent tw-border-collapse">
                  <thead>
                    <tr>
                      <th
                        className={
                          "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B] "
                        }
                      >
                        Tur Tarihi
                      </th>
                      <th
                        className={
                          "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B]"
                        }
                      >
                        Tur Adı
                      </th>
                      <th
                        className={
                          "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B]"
                        }
                      >
                        Gece Gün
                      </th>
                      <th
                        className={
                          "tw-px-6 tw-align-middle tw-border tw-border-solid tw-py-3 tw-text-xs tw-uppercase tw-whitespace-nowrap tw-font-semibold tw-text-left tw-bg-[#F8FAFC] tw-text-[#64748B]"
                        }
                      >
                        Durum
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products
                      .sort((a, b) => a?.tarih?.localeCompare(b?.tarih))
                      .slice(0, 10)
                      .map((item) => (
                        <tr key={item?._id}>
                          <th className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-text-left tw-flex tw-items-center tw-p-3">
                            {item?.tarih}
                          </th>
                          <td className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                            <Link
                              to={`/${item?.slug}`}
                              className="tw-underline"
                            >
                              {item?.name}
                            </Link>
                          </td>
                          <td className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                            {item?.gecegun}
                          </td>
                          <td className="tw-px-6 tw-align-middle tw-text-xs tw-whitespace-nowrap tw-p-3">
                            {item?.category}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TurTakvimi;
